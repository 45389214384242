import React, { Component } from 'react';
import SearchBar from '../Layout/SearchBar';
import $ from 'jquery';
import 'datatables.net';
import * as Highcharts from 'highcharts';
import { getAccount } from '../../api/account';
import { getAccountDetail, setTransferToggleCoinApi } from '../../api/admin/account';
import Transfers from './Transfers/Transfers';
import OtherExtrinsics from './OtherExtrinsics/OtherExtrinsics';
import Loader from '../Layout/Loader';
import Breadcrumb from '../Layout/Breadcrumb';
import './Accounts.css';

class Accounts extends Component {

    state = { 
        account :   {
            user: {},
            wallet: {}
        },
        transfers   :   {
            data    :   [],
            total   :   0
        },
        pagination  :   null,
        selected    :   'transfers',
        isRefresing :   false,
        adminData: {},
        isLoadingAdminData: true,
        isAdminLoggedIn : localStorage.getItem('access-key') && localStorage.getItem('secret-key')
    }
    async componentDidMount(regDataTable = true) {
        
        if(regDataTable) this.registerJQuery();

        try {
            const { data } = await getAccount(this.props.match.params.address);
            this.setState({ ...this.state, account: data, isRefresing: false }, () => this.plotHighChart());
        } catch(e) {

        }

        if(!this.state.isAdminLoggedIn) return;

        try {
            const { data } = await getAccountDetail(this.props.match.params.address);
            this.setState({ ...this.state, adminData: data, isLoadingAdminData: false });
        } catch(e) {
            this.setState({ ...this.state, adminData: { user: {}, wallet: {} }, isLoadingAdminData: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.address !== prevProps.match.params.address) {
            this.componentDidMount(false);
        }
    }

    setTransferToggle = async (user_name, param) => {
        try {
            await setTransferToggleCoinApi(user_name, param);
            this.setState({ ...this.state, adminData: { ...this.state.adminData, user: { ...this.state.adminData.user, toggle_transfer_coin: param } }});
        } catch(e) {}
    }
 
    plotHighChart = () => {
        Highcharts.chart({
            chart: {
                backgroundColor: 'transparent',
                renderTo: 'PieChart',
                type: 'pie',
                height: 160,
                margin: [null],
                spacingTop: 10,
                spacingRight: 0,
                spacingBottom: 0,
            },
            tooltip: {
                enabled: false
            },
            legend: {
                layout: 'vertical',
                title: false,
                align: 'right',
                verticalAlign: 'middle',
                labelFormatter: function () {
                    return this.y + ' ' + '<br>' + this.name;
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    shadow: {
                        color: 'grey',
                        width: 2,
                        offsetX: 0.1,
                        offsetY: 0.1,
                        opacity: 2
                    }
                }
            },
    
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0
            },
    
            series: [{
                data: [{
                    color: "green",
                    name: "Received",
                    y: this.state.account.trx_in_count
                }, {
                    color: "red",
                    name: "Transfers",
                    y: this.state.account.trx_out_count
                }],
                size: '100%',
                innerSize: '80%',
                showInLegend: true,
                dataLabels: {
                    enabled: false
                }
            }],
    
            title: false,
        }, function (chart) {
            var W = chart.plotWidth + 35,
                h = $('#PieChart').height();
    
            if (W < 412) {
                chart.series[0].update({
                    center: ['50%', '50%']
                });
            } else {
                chart.series[0].update({
                    center: [h / 2 - 30, '50%']
                });
            }
        });
    }


    registerJQuery = () => {
        function format(d) {
            // `d` is the original data object for the row
            return '<div class="WhiteBox lightGrey"><table cellpadding="5" cellspacing="0" border="0" class="w-100">' +
                '<tr>' +
                '<td>Docs</td>' +
                '<td>Transfer succeeded. \[from, to, value\]</td>' +
                '</tr>' +
                '<tr>' +
                '<td>AccountId</td>' +
                '<td><a href="#" id="sample">2bsRXVKED2pzB1xHgLRJm7PMufsTBDU8tmTrgJ9a8teYybNp</a><button type="button" class="btn p-0 ml-2 bg-transparent shadow-none" href="#" onclick="CopyToClipboard(\'sample\');return false;"><i class="far fa-copy"></i></button></td>' +
                '</tr>' +
                '<tr>' +
                '<td>AccountId</td>' +
                '<td><a href="#" id="sample2">2bsRXVKED2pzB1xHgLRJm7PMufsTBDU8tmTrgJ9a8teYybNpOO</a><button type="button" class="btn p-0 ml-2 bg-transparent shadow-none" href="#" onclick="CopyToClipboard(\'sample2\');return false;"><i class="far fa-copy"></i></button></td>' +
                '</tr>' +
                '<tr>' +
                '<td>Balance</td>' +
                '<td>8,135.9842 </td>' +
                '</tr>' +
                '</table></div>';
        };

        var table = $('#AccountPageTable').DataTable({
            "fnInfoCallback": function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                return '';//iStart + " - " + iEnd + " of " + iMax;
            },
            order: [1, 'asc'],
            lengthChange: false,
            pageLength: 10,
            info: true,
            paging: !true,
            ordering: false,
            autoWidth: false,
            language: {
                paginate: {
                    previous: '<i class="fas fa-arrow-left"></i>',
                    next: '<i class="fas fa-arrow-right"></i>',
                },
                sEmptyTable:
                    '<div>' +
                    '</div>',
            },
            initComplete: (settings, json) => {
                $('#AccountPageTable_info').appendTo('.AppendInfo');
                $('#AccountPageTable_paginate').appendTo('.AppendPagination');
            },
        });
    
        // Add event listener for opening and closing details
        $('#AccountPageTable tbody').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function CopyToClipboard(id) {
            var r = document.createRange();
            r.selectNode(document.getElementById(id));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(r);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
        }

        // $('.CustomDropDown').selectpicker();
    }

    setPaginationBar = (pagination = null) => {
        this.setState({ ...this.state, pagination })
    }

    render() {

        const { address, meta, trx_in_count, trx_out_count, balance } = this.state.account;
        const { user, wallet } = this.state.adminData;
        const { isAdminLoggedIn, isLoadingAdminData } = this.state;

        return (
            <main className="mb-5">
                <Breadcrumb />
                <section className="mb-4" id="SearchBox">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </section>
           
                <section className="mb-4" id="Statistics">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-4 mb-lg-0">
                                <div className="WhiteBox rounded p-4 h-100">
                                    <div className="row">
                                        <div style={{ marginTop: 30 }} className="col-6 col-md-2 col-sm-3 mb-3 mb-md-0 mx-auto mx-sm-0">
                                            <img src="https://nucoin.com.au/assets/images/nucoin-logo.png" alt="Image" style={{ width: '50%', marginLeft: '25%', marginTop: '10%' }} />
                                        </div>
                                        <div className={`col-md-4 col-sm-9 mb-3 mb-md-0`}>
                                            <h7 className="mb-2"> <b style={{ color: '#1996fc' }}>{ meta?.account_name && meta?.visible ? ` ${meta?.account_name} ` : <br/> }</b> {address  || <Loader/>}</h7>
                                            <h5 style={{ marginTop: '7px' }} className="mb-2">{balance} (NUC)</h5>
                                            <p className="mb-1"><a href="#">https://nucoin.com.au/</a></p>
                                            <a className="mr-2" href="#"><i className="fab fa-twitter-square fa-2x" /></a>
                                            <a className="mr-2" href="#"><i className="fab fa-github-square fa-2x" /></a>
                                        </div>
                                        <div style={{ marginTop: '13px' }} className="col-xl col-sm align-self-center">
                                            <div className="TextBox">
                                                Decimals
                <h5 className="pt-1">18</h5>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '13px' }} className="col-xl col-sm align-self-center">
                                            <div className="TextBox">
                                                Received
                <h5 className="pt-1">{ trx_in_count === undefined ? <Loader/> : trx_in_count }</h5>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '13px' }} className="col-xl col-sm align-self-center">
                                            <div className="TextBox">
                                                Transfer
                <h5 className="pt-1"><a>{ trx_out_count === undefined ? <Loader/> : trx_out_count }</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div style={{ height: '181px' }} className="WhiteBox px-3">
                                    <figure className="highcharts-figure">
                                        <div id="PieChart" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    isAdminLoggedIn && (
                        <section className="mb-4" id="Statistics1">
                            <div className="container">
                                <div className="row">
                                    {
                                        isLoadingAdminData ? (
                                            <div className="col-lg-12 mb-4 mb-lg-0">
                                                <div className="WhiteBox rounded p-4 h-100">
                                                    <div className="row">
                                                        <h5 style={{ width: '100%' }} className="text-center">
                                                            <Loader/> Loading...
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : !isLoadingAdminData && !user.user_name ? (
                                            <div className="col-lg-12 mb-4 mb-lg-0">
                                                <div className="WhiteBox rounded p-4 h-100">
                                                    <div className="row">
                                                        <h5 style={{ width: '100%' }} className="text-center">
                                                            No Information Found!
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                        <div className="col-lg-12 mb-4 mb-lg-0">
                                            <div className="WhiteBox rounded p-4 h-100">
                                                <div className="row">
                                                    <div className={`col-md-4 col-sm-9 mb-3 mb-md-0`}>
                                                        <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Username: </h5> <h7 className="m-0">{ user?.user_name }</h7></div>
                                                        <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Full Name: </h5> <h7 className="m-0">{ user?.first_name } { user?.middle_name } { user?.last_name }</h7></div>
                                                        <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Gender: </h5> <h7 className="m-0">{ user?.gender }</h7></div>
                                                        <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Mobile Number: </h5> <h7 className="m-0">{ user?.mobile_number }</h7></div>
                                                        <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Email: </h5> <h7 className="m-0">{ user?.email }</h7></div>
                                                        <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Profile Verified: </h5> <h7 className="m-0">{ user?.isVerified ? 'YES' : 'NO' }</h7></div>
                                                    </div>
                                                    <div className={`col-md-4 col-sm-9 mb-3 mb-md-0`}>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Country: </h5> <h7 className="m-0">{ user?.country_name || 'N/A' }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">User Registration Date: </h5> <h7 className="m-0">{ user?.create_time ? new Date(user.create_time).toLocaleDateString() : 'N/A' }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Miner Fee Received: </h5> <h7 className="m-0">{ user?.miner_fees_received ? 'YES' : 'NO' }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Miner Fee Receiving Date: </h5> <h7 className="m-0">{ user?.fees_received_date ? new Date(user.fees_received_date).toLocaleDateString() : 'N/A' }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Date of Birth: </h5> <h7 className="m-0">{ user?.date_of_birth ? new Date(user.date_of_birth).toLocaleDateString() : 'N/A' }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">User can Transfer Coins: </h5> <h7 className="m-0">{ user?.toggle_transfer_coin ? 'YES' : 'NO' }</h7></div>
                                                    </div>
                                                    <div className={`col-md-4 col-sm-9 mb-3 mb-md-0`}>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Wallet Name: </h5> <h7 className="m-0">{ wallet?.account_name }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Wallet Creation Time: </h5> <h7 className="m-0">{ wallet ? new Date(wallet?.create_time).toLocaleString() : 'N/A' }</h7></div>
                                                    <div className="mb-1 d-flex justify-content-between"><h5 className="m-0">Transfer Feature is { user?.toggle_transfer_coin ? 'Available' : 'Not Available' }</h5>: 
                                                            <label className="switch">
                                                                <input onClick={() => {
                                                                    this.setTransferToggle(user?.user_name, user?.toggle_transfer_coin == 1 ? 0 : 1);
                                                                }} checked={user?.toggle_transfer_coin == 1 ? true : false} type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    }
                                    
                                </div>
                            </div>
                        </section>
                    )
                    
                }
                
                <section id="ExtrinsicsPage">
                    <div className="container">
                        <div className="row align-items-center mb-3">
                            <div className="col-lg-auto col-sm-6 mr-sm-auto">
                                {/* 
                                    <i className="fas fa-redo-alt"></i>
                                */}
                                {/* <h5 className="mb-0"><i className="fas fa-chart-bar" aria-hidden="true" /> Top 1,000 holders </h5> */}
                            </div>
                            <div className="col-lg-auto col-sm-6 d-sm-flex align-items-center mt-lg-0 mt-3">
                                <label className="mr-2 mb-2 mb-sm-0 fw-500">Method</label>
                                <select onChange={e => {
                                        this.setState({ ...this.state, selected: e.target.value });
                                    }} className="form-control border CustomDropDown mr-2">
                                    <option value="transfers">Transfers</option>
                                    <option value="others">Other</option>
                                </select>
                                <i style={{ cursor: 'pointer' }} onClick={() => this.setState({ ...this.state, isRefresing: true }, () => this.componentDidMount(false) ) } className={`fas fa-redo-alt ${this.state.isRefresing && 'fas fa-spin'}`}></i>
                                {/* style={{ position: 'fixed' }} */}
                            </div>
                        </div>
                        <div className="WhiteBox rounded p-4">
                            <table className="Data_tables rounded mb-4" ref="AccountPageTableRef" id="AccountPageTable">
                                {
                                    this.state.selected === 'transfers' ? <Transfers address={this.props.match.params.address} setPagination={this.setPaginationBar} isRefresing={ this.state.isRefresing} paginationsCount = {Math.round(((this.state.account.trx_out_count+this.state.account.trx_in_count)/20) +1)}/> : <OtherExtrinsics address={this.props.match.params.address} setPagination={this.setPaginationBar} isRefresing={ this.state.isRefresing}/>
                                } 
                            </table>
                            { this.state.pagination }
                            <div className="row align-items-center justify-content-between gx-5">
                                <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                    {/* <a className="btn BtnDark" href="#"><i className="fas fa-download mr-2" /> Download Page Data</a> */}
                                </div>
                                <div className="col-md-auto AppendInfo text-center text-md-right mb-3 mb-md-0" />
                                <div className="col-md-auto AppendPagination text-center text-md-right" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default Accounts;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

    render() {

        return (
            <React.Fragment>
                {
                    this.props.isHome ? (
                        <footer className="px-sm-5 p-3 py-sm-4">
                            <div className="container">
                                <section id="PartnerSec">
                                    <h3 className="text-center text-white mb-4">Partners</h3>
                                    <div id="Partners" className="carousel slide" data-ride="carousel" data-interval="false">
                                        <div className="carousel-inner row mx-auto" role="listbox">
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3 active">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-1.webp" className="img-fluid mx-auto d-block" alt="Fedex" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-2.png" className="img-fluid mx-auto d-block" alt="Uber" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-3.webp" className="img-fluid mx-auto d-block" alt="Hanco" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-4.webp" className="img-fluid mx-auto d-block" alt="Kaau" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-5.webp" className="img-fluid mx-auto d-block" alt="Careem" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-6.webp" className="img-fluid mx-auto d-block" alt="BB" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-7.png" className="img-fluid mx-auto d-block" alt="Nafisa" />
                                                </div>
                                            </div>
                                            <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                                                <div className="ImgBox">
                                                    <img src="images/partner_logo-4.webp" className="img-fluid mx-auto d-block" alt="img1" />
                                                </div>
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#Partners" role="button" data-slide="prev">
                                            <i className="fas fa-chevron-left" />
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#Partners" role="button" data-slide="next">
                                            <i className="fas fa-chevron-right" />
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </section>
                                <div className="d-md-flex justify-content-between align-items-center border-bottom border-top py-3 mb-3">
                                    <div className="text-white mb-3 mb-md-0">
                                        {/* <span>Donate (NUC) :</span>
                                        <a data-v-4d0444a4 href="#" target="_blank">2f26iQ5tzF9Utmpzf1tPWykpxkgoWHSbNeo1bsxY5PcZdbZb</a> */}
                                    </div>
                                    <div className="SocialLinks">
                                        <a className="mr-2" href="#"><i className="fab fa-twitter-square fa-2x" /></a>
                                        <a className="mr-2" href="#"><i className="fab fa-github-square fa-2x" /></a>
                                        <a className="mr-2" href="#"><i className="fab fa-medium fa-2x" /></a>
                                        <a href="#"><i className="fas fa-envelope fa-2x" /></a>
                                    </div>
                                </div>
                                <div className="d-lg-flex justify-content-between align-items-center">
                                    <div className="text-white">
                                        Nuscan © 2021 - Developed By Nuscan Team
                                </div>
                                    <div className="SocialLinks">
                                        <Link to={'/privacy'} > <a className="mr-2">Privacy Policy</a></Link>
                                        <Link to={'/terms'} > <a className="mr-2">Terms Of Use</a></Link>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    ) : (
                    <footer className="px-sm-5 p-3 py-sm-4">
                        <div className="container">
                            <div className="d-lg-flex justify-content-between align-items-center text-center text-xl-right">
                                <div className="text-white pr-3 mb-3 mb-lg-0">
                                    Nuscan © 2021 - Developed By Nuscan Team
                                </div>
                                <div className="SocialLinks pr-3 mb-3 mb-lg-0">
                                    <Link to={'/privacy'} > <a className="mr-2" href="privacy">Privacy Policy</a></Link>
                                    <Link to={'/terms'} > <a className="mr-2" href="terms">Terms Of Use</a></Link>
                                </div>
                                <div className="SocialLinks">
                                    <a className="mr-2" href="#"><i className="fab fa-twitter-square fa-2x" /></a>
                                    <a className="mr-2" href="#"><i className="fab fa-github-square fa-2x" /></a>
                                    <a className="mr-2" href="#"><i className="fab fa-medium fa-2x" /></a>
                                    <a href="#"><i className="fas fa-envelope fa-2x" /></a>
                                </div>
                            </div>
                        </div>
                    </footer>
                    )
                }
            </React.Fragment>
        );
    }
}
export default Footer;

const copyToClipboard = (id) => {
    var r = document.createRange();
    r.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
}

export const renderCopyButton = (id) => (
    <button type="button" className="btn p-0 ml-2 bg-transparent shadow-none" onClick={() => copyToClipboard(id)}><i className="far fa-copy" />
    </button>  
)
import React, { Component } from 'react';
import { getBlockDetailByID } from '../../api/block';
import timeStamp from 'time-stamp';
import timeAgo from 'time-ago';
import Loader from '../Layout/Loader';
import Extrinsics from './ListItems/Extrinsics';
import Log from './ListItems/Log';
import Event from './ListItems/Event';
import { Link } from 'react-router-dom';
import SearchBar from '../Layout/SearchBar';
import $ from 'jquery';
import 'datatables.net';
import downloadAsJson from '../../utils/downloadAsJson';
import { renderCopyButton } from '../../utils/copyText';
import Breadcrumb from '../Layout/Breadcrumb';
const moment = require('moment');
class BlockDetails extends Component {

    state = {
        block       : {},
        notFound    : false,
        expandExtrinsic :   false,
        expandLogs :   false,
        timeTypeToggle  :   false
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.block_id !== prevProps.match.params.block_id) {
            this.componentDidMount(null, false);
        }
    }

    registerJQuery = () => {

	function format ( d ) {
		// `d` is the original data object for the row
		return '<div class="WhiteBox lightGrey"><table cellpadding="5" cellspacing="0" border="0" class="w-100">'+

				'<td>Data</td>'+
				'<td>0xa2f9f04c238140c0ebe96fe795bad6fa35937553cacc187ec6a8385afd8e5840</td>'+
				'</tr>'+
				'<tr>'+
				'<td>Engine</td>'+
				'<td>1601662832</td>'+
				'</tr>'+
				'</table></div>';
	}
    
    var table = $('#LogTable').DataTable( {
        "fnInfoCallback": function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
            return '';
        },
        order: [1, 'asc'],
        lengthChange: false,
        pageLength: 10,
        info: true,
        paging: !true,
        ordering: false,
        autoWidth: false,
        language: {
            paginate: {
                previous: '<i class="fas fa-arrow-left"></i>',
                next: '<i class="fas fa-arrow-right"></i>',
            },
            sEmptyTable:
                    '<div>' +
                    '</div>',
        },
        initComplete: (settings, json) => {
            $('#LogTable_info').appendTo('.AppendInfo');
            $('#LogTable_paginate').appendTo('.AppendPagination');
        },
    } );    
    
     $('#EventTable').DataTable( {
        "fnInfoCallback": function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
            return '';
        },
        order: [1, 'asc'],
        lengthChange: false,
        pageLength: 10,
        info: true,
        paging: !true,
        ordering: false,
        autoWidth: false,
        language: {
            paginate: {
                previous: '<i class="fas fa-arrow-left"></i>',
                next: '<i class="fas fa-arrow-right"></i>',
            },
            sEmptyTable:
                    '<div>' +
                    '</div>',
        },
        // initComplete: (settings, json) => {
        //     $('#LogTable_info').appendTo('.AppendInfo');
        //     $('#LogTable_paginate').appendTo('.AppendPagination');
        // },
    } );    

    
    var extrinsicsTable = $('#ExtrinsicsTable').DataTable( {
        "fnInfoCallback": function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
            return '';
        },
        order: [1, 'asc'],
        lengthChange: false,
        pageLength: 10,
        info: true,
        paging: !true,
        ordering: false,
        autoWidth: false,
        language: {
            paginate: {
                previous: '<i class="fas fa-arrow-left"></i>',
                next: '<i class="fas fa-arrow-right"></i>',
            },
            sEmptyTable:
                    '<div>' +
                    '</div>',
        },
        initComplete: (settings, json) => {
            $('#LogTable_info').appendTo('.AppendInfo');
            $('#LogTable_paginate').appendTo('.AppendPagination');
        },
    } );

    $('#LogTable tbody').on('click', 'td.details-control', function () {
  
        var tr = $(this).closest('tr');
        var row = table.row( tr );
        if ( row.child.isShown() ) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
        }
        else {
            // Open this row
            row.child( format(row.data()) ).show();
            tr.addClass('shown');
        }
    });

	//Copy Field
    function CopyToClipboard(id) {
        var r = document.createRange();
        r.selectNode(document.getElementById(id));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }
    }

    componentDidMount(block_id, jqueryInvoke = true) {
        this.setState({ ...this.state, block: {} }, async () => {
            try {
                const response = await getBlockDetailByID(block_id || this.props.match.params.block_id);
                this.setState({ ...this.state, block: response.data });
            } catch(e) {
                this.setState({ ...this.state, notFound: true });
            }
        });

        if(jqueryInvoke) this.registerJQuery();
    }

    render() {

        const { expandExtrinsic, expandLogs, timeTypeToggle } = this.state;
        const { block_id, hash, parent_hash, state_root, extrinsics_root, timestamp, extrinsics, events, digest } = this.state.block;
        const blockNumber = parseInt(this.props.match.params.block_id);

        return (
            <main className="mb-5">
                <Breadcrumb />
                <section id="SearchBox">
                    <div className="container">
                        <div className="row justify-content-between align-items-center mb-4">
                            <div className="col-md-3 mb-3 mb-md-0 d-flex align-items-center">
                                <Link className="btn BtnDark shadow-none py-0 px-2 mr-2" to={`/block/${blockNumber - 1}`}><i className="fas fa-chevron-left" aria-hidden="true"/></Link>
                                <h4 className="mb-0">Block# { block_id || <Loader /> }</h4>
                                <Link className="btn BtnDark shadow-none py-0 px-2 ml-2" to={`/block/${blockNumber + 1}`}><i className="fas fa-chevron-right" aria-hidden="true"/></Link>
                            </div>
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="BlockDetailPage">
                    <div className="container">
                        <div className="WhiteBox rounded py-3 px-4 mb-4">
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Timestamp</label>
                                </div>
                                <div className="col-md-10">
                                    { timestamp ?  moment(timestamp).format('YYYY-MM-DD hh:mm:ss A'): <Loader />}
            </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Status</label>
                                </div>
                                <div className="col-md-10">
                                Finalized <i className="far fa-check-circle text-success Fsize_20 pr-1 align-center" aria-hidden="true" />
            </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Hash</label>
                                </div>
                                <div className="col-md-10">
                                    <a className="break-all" id={hash}>{ hash || <Loader /> }</a>
                                    { renderCopyButton(hash) }
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Parent Hash</label>
                                </div>
                                <div className="col-md-10">
                                    <Link className="break-all" to={`/block/${block_id - 1}`} >{ parent_hash || <Loader /> }</Link>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">State Root</label>
                                </div>
                                <div className="col-md-10 break-all">
                                    { state_root || <Loader /> }
            </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Extrinsics Root</label>
                                </div>
                                <div className="col-md-10 break-all">
                                    { extrinsics_root || <Loader /> }
            </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Block Time</label>
                                </div>
                                <div className="col-md-10">
                                    { timestamp ? timeAgo.ago(timestamp) : <Loader/> }
            </div>
                            </div>
                        </div>
                        <div className="WhiteBox rounded py-3 px-4 mb-4">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="Extrinsics-tab" data-toggle="tab" href="#Extrinsics" role="tab" aria-controls="Extrinsics" aria-selected="true">Extrinsics ({ extrinsics ? extrinsics.length : <Loader/> })</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="log-tab" data-toggle="tab" href="#log" role="tab" aria-controls="log" aria-selected="false">Log ({ digest ? digest.length : <Loader /> })</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="events-tab" data-toggle="tab" href="#events" role="tab" aria-controls="events" aria-selected="false">Events ({ events ? events.length : <Loader /> })</a>
                                </li>
                                
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="Extrinsics" role="tabpanel" aria-labelledby="Extrinsics-tab">
                                    <div className="table-responsive">
                                        <table id="ExtrinsicsTable" className="Data_tables rounded mb-4">
                                            <thead>
                                                <tr>
                                                    <th width="10%">Extrinsic ID</th>
                                                    <th width="10%">Hash</th>
                                                    <th width="15%">Time <i onClick={() => this.setState({ ...this.state, timeTypeToggle: !timeTypeToggle }) } style={{ cursor: 'pointer', fontSize: '12px', color: '#58595a' }} className={`fa${ timeTypeToggle ? 's' : 'r' } fa-clock`}></i></th>
                                                    <th width="15%">Section</th>
                                                    <th width="15%">Method</th>
                                                    <th width="20%">Data <i onClick={() => this.setState({ ...this.state, expandExtrinsic: !expandExtrinsic }) } style={{ cursor: 'pointer' }} className={`fas fa-caret-${ expandExtrinsic ? 'up' : 'down' }`}></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    
                                                    extrinsics && extrinsics.map((extrinsic, index) => <Extrinsics key={index} {...extrinsic} isExpaned={expandExtrinsic} timeTypeToggle={timeTypeToggle} />)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        extrinsics && extrinsics.length > 0 ? (
                                            <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                                <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                                    if(extrinsics.length > 0) downloadAsJson(extrinsics, 'extrinsics');
                                                }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                                            </div>
                                        ) : null
                                    }
                                    {/* <div className="row align-items-center justify-content-between gx-5">
                                        <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                            <a className="btn BtnDark" href="#">View All</a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="tab-pane fade" id="log" role="tabpanel" aria-labelledby="Log-tab">
                                    <table id="LogTable" className="Data_tables rounded mb-4">
                                        <thead className="pt-2">
                                            <tr>
                                                <th width="25%">Block</th>
                                                <th width="25%">Type</th>
                                                <th width="25%">Data <i onClick={() => this.setState({ ...this.state, expandLogs: !expandLogs }) } style={{ cursor: 'pointer' }} className={`fas fa-caret-${ expandLogs ? 'up' : 'down' }`}></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { digest && digest.map((log, index) => <Log key={index} block_id={block_id} { ...log } isExpaned={expandLogs} />) }
                                        </tbody>
                                    </table>
                                    {
                                        digest && digest.length > 0 ? (
                                            <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                                <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                                    if(digest.length > 0) downloadAsJson(digest, 'logs');
                                                }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                                            </div>
                                        ) : null
                                    }
                                    
                                    {/* <div className="row align-items-center justify-content-end gx-5">
                                        <div className="col-md-auto AppendInfo text-center text-md-right mb-3 mb-md-0" />
                                        <div className="col-md-auto AppendPagination text-center text-md-right" />
                                    </div> */}
                                </div>
                                <div className="tab-pane fade" id="events" role="tabpanel" aria-labelledby="events-tab">
                                    <table id="EventTable" className="Data_tables rounded mb-4">
                                        <thead className="pt-2">
                                            <tr>
                                                <th width="15%">Block</th>
                                                <th width="15%">ID</th>
                                                <th width="20%">Method</th>
                                                <th width="20%">Section</th>
                                                <th width="10%">Nonce</th>
                                                <th width="15%">Data <i onClick={() => this.setState({ ...this.state, expandLogs: !expandLogs }) } style={{ cursor: 'pointer' }} className={`fas fa-caret-${ expandLogs ? 'up' : 'down' }`}></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { events && events.map((event, index) => {
                                                return <Event key={index} block_id={block_id} { ...event } isExpaned={expandLogs} />}) }
                                        </tbody>
                                    </table>
                                    {/* {
                                        events && events.length > 0 ? (
                                            <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                                <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                                    if(digest.length > 0) downloadAsJson(digest, 'logs');
                                                }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                                            </div>
                                        ) : null
                                    } */}
                                    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        );
    }
}

export default BlockDetails;

import axios from 'axios';

export default function ({ url, method, data = {},headers = { Accept: 'application/json' }}) {
    return axios({
        url,
        method,
        data,
        headers
    });
}

export const doAxiosCall = (data) => {
    return axios(data);
}

import  React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import timeAgo from 'time-ago';
import commaNumber from 'comma-number';
import moment from 'moment';

export default ({ hash, block_id, section, method, is_signed, index, timestamp, timeTypeToggle = false }) => {

    const [ timeToggle, setTimeToggle ] = useState(false);

    useEffect(() => {
        setTimeToggle(timeTypeToggle)
    }, [timeTypeToggle]);

    return (
        <tr>
            <td><Link to={`/block/${block_id}`}>{block_id}-{index}</Link></td>
            <td><Link to={`/block/${block_id}`}>{commaNumber(block_id)}</Link></td>
            <td><Link  data-toggle="tooltip" title={hash} style={{ width: '220px' }}  className="WalletDots" to={`/extrinsic/${hash}`}>{hash}</Link></td>
            <td><a>{ section }</a></td>
            <td><a>{ method }</a></td>
            <td><a>{ is_signed ? 'YES' : 'NO' }</a></td>
            <td>{ timestamp ? timeToggle ? moment(timestamp).format('lll') : timeAgo.ago(timestamp) : 'N/A' }</td>
        </tr>
    )
}
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import timeAgo from 'time-ago';
import moment from 'moment';

export default ({ block_id, hash, signer, to_address, section, method, data, address, timestamp, status, index, page = 0, isExpaned = false, timeTypeToggle = false }) => {

    const [ isShown, setShown ] = useState(false);

    useEffect(() => {
        setShown(isExpaned);
    }, [ isExpaned ]);

    const [ timeToggle, setTimeToggle ] = useState(false);

    useEffect(() => {
        setTimeToggle(timeTypeToggle)
    }, [timeTypeToggle]);

    return (
        <React.Fragment>
            <tr className={ isShown ? 'shown' : '' }>
                {/* <td><span className="RankCircle d-inline-block text-center rounded-circle bg-success text-white">{index + (page > 0 ? page * 20 : 0)}</span></td> */}
                <td><Link data-toggle="tooltip" title={hash} className="WalletDots w-100" to={`/extrinsic/${hash}`}>{hash}</Link></td>
                <td>{ section }</td>
                <td>{ method }</td>
                <td>{ timestamp ? timeToggle ? moment(timestamp).format('lll') : timeAgo.ago(timestamp) : 'N/A' }</td>
                <td>{ status === 'ExtrinsicFailed' ? <i title="Errored" className="far fa-times-circle text-danger Fsize_20 pr-1 align-center" aria-hidden="true" /> : <i title="Success" className="far fa-check-circle text-success Fsize_20 pr-1 align-center" aria-hidden="true" /> }</td>
                <td className="details-control">
                    <button onClick={e => setShown(!isShown)} type="button" className="btn BtnDark shadow-none py-0 px-2"><i
                            className="fas fa-chevron-right"></i></button>
                </td>
            </tr>
            <tr style={{ display: isShown ? '' : 'none' }} >
                <td colspan="6">
                    <div className="WhiteBox lightGrey">
                        <table className="w-100" cellspacing="0" cellpadding="5" border="0">
                            <tbody>
                                {
                                    JSON.parse(data || [] ).map((value, index) => (
                                        <tr>
                                            <td>{ index }</td>
                                            <td>{ JSON.stringify(value || {}) }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    )
}
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import { findPathIfExist } from '../../api/search';

class SearchBar extends Component {

    state = {
        search      :   null,
        isRedirect  :   null,
        param       :   null,
        isSearching :    false,
        onchange:   (value) => this.setState({ ...this.state, search: value })
    }

    searchHandler = (e) => {
        e && e.preventDefault();
        this.setState({ ...this.state, isSearching: true }, async () => {
            try {
                const { data: { redirect, param } } = await findPathIfExist(this.state.search);
                this.setState({ ...this.state, isRedirect: redirect, param }, () => {
                    this.setState({ ...this.state, search: null, isRedirect: null, param: null, search: null });
                });
            } catch(e) {
    
            }
            this.setState({ ...this.state, isSearching: false });
        })
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            this.searchHandler();
        }
    }


    render() {
        const { isSearching } =  this.state;
        return (
            <React.Fragment>
                <div className="SearchBox input-group flex-column flex-sm-row">
                    <input onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.state.onchange(e.target.value)
                        }} onKeyDown={this.handleKeyPress} type="text" value={this.state.search} className="form-control py-2 shadow-none h-45px" placeholder="Search by Block / Extrinsic / Account" />
                    <div >
                        <button disabled={isSearching} onClick={this.searchHandler} className="btn BtnDark px-4  font-weight-bold h-45px rounded-0  w-150px" type="button">Search { isSearching && <Loader/> }</button>
                    </div>
                </div>
                { this.state.isRedirect && <Redirect to={`/${this.state.isRedirect}/${this.state.param}`} /> }
            </React.Fragment>
        )
    }
}

export default SearchBar;

import {NFT_CONTRACT_ADDRESS,NFT_SALE_CONTRACT_ADDRESS,NFT_AUCTION_CONTRACT_ADDRESS} from '../config.json'

export default (address)=>{

    if(!address.startsWith('0x'))
        address = '0x'+ address
        
    if(address === NFT_CONTRACT_ADDRESS)
        return "NFT Contract Address"
    else if(address === NFT_SALE_CONTRACT_ADDRESS)
        return "NFT Sale Contract Address"
    else if(address === NFT_AUCTION_CONTRACT_ADDRESS)
        return "NFT Auction Contract Address"
    else
        return "Contract Address"
}
const INITIAL_STATE = {
    extrinsics  :   []
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'SET_EXTRINSICS_LIST':
        return { ...state, extrinsics: action.payload };
      default:
        return state;
    }
  }
const {
    naclDecrypt,
    naclEncrypt,
    randomAsU8a,
    cryptoWaitReady, decodeAddress, signatureVerify 
  } = require('@polkadot/util-crypto');
  const {
    stringToU8a,
    u8aToString,
    u8aToHex
  } = require('@polkadot/util');
  
  const { encodeAddress } = require('@polkadot/keyring');
  const { Keyring }= require ('@polkadot/keyring');

async function generateSignature(mnemonic_code, address, usi){
    try {
        let mnemonic_code1;
        if (mnemonic_code.includes('{"string":"')){
            mnemonic_code1 = mnemonic_code.substring(
                mnemonic_code.indexOf('"') + 10,
                mnemonic_code.lastIndexOf('"')
                )
                mnemonic_code1 = mnemonic_code1
        } else {
            mnemonic_code1 = mnemonic_code
        } 

        const keyring = new Keyring({
            type: 'sr25519',
        });

        const alice = keyring.addFromUri(mnemonic_code1);

        if (alice.address != address) {
            return false;
        }
        const message = stringToU8a(JSON.stringify({ usiCount: usi, accountId: address }));
        const signature = alice.sign(message);
        var hash = u8aToHex(signature);
        const { isValid } = signatureVerify(message, signature, alice.address);
        if (isValid === true) {
            return hash;
        }return false;
    } catch (e) {
        return false;
    }
}

export default generateSignature;

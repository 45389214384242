const INITIAL_STATE = {
    blocks  :   [],
    signedExtrinsicsCount : 0,
    nucoinUSDPrice : 0,
    transfersCount  : 0, 
    totalIssuance   : null,
    holdersCount    : null,
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'SET_BLOCK_LIST':
        return { ...state, blocks: action.payload };
      case 'SET_SIGNED_EXTRINSICS_COUNT':
        return { ...state, signedExtrinsicsCount: action.payload };
      case 'SET_NUCOIN_USD_PRICE':
        return { ...state, nucoinUSDPrice: action.payload };
      case 'SET_TRANSFERS_COUNT':
        return { ...state, transfersCount: action.payload };
      case 'SET_TOTAL_ISSUANCE':
        return { ...state, totalIssuance: action.payload };
      case 'SET_HOLDERS_COUNT':
        return { ...state, holdersCount: action.payload };
      default:
        return state;
    }
  }
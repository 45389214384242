import  React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default ({ block_id, preRuntime = null, seal = null, isExpaned }) => {

    const [ isShown, setShown ] = useState(false);

    useEffect(() => {
        setShown(isExpaned);
    }, [ isExpaned ]);

    return (
        <React.Fragment>
        <tr className={ isShown ? 'shown' : '' }>
            <td><Link to={`/block/${block_id}`}>{ block_id }</Link></td>
            <td><a href="#">{ preRuntime ? 'PreRuntime ' : 'Seal' }</a></td>
            <td className="details-control">
                <button onClick={e => setShown(!isShown)} type="button" className="btn BtnDark shadow-none py-0 px-2"><i
                        className="fas fa-chevron-right"></i></button>
            </td>
            {/* <td><a className="WalletDots">{ preRuntime ? preRuntime.join(', ') : seal && seal.join(', ') }</a></td> */}
            {/* <td className="details-control"><button type="button" className="btn BtnDark shadow-none py-0 px-2"><i className="fas fa-chevron-right"></i></button></td> */}
        </tr>
        <tr style={{ display: isShown ? '' : 'none' }} >
                <td colspan="4">
                    <div className="WhiteBox lightGrey">
                        <table className="w-100" cellspacing="0" cellpadding="5" border="0">
                            <tbody>
                                {
                                    (seal!==null)?
                                    (preRuntime || seal).map((value, index) => (
                                        <tr>
                                            <td>{ index }</td>
                                            <td>{ JSON.stringify(value || {}) }</td>
                                        </tr>
                                    )):
                                    null
                                }
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    )
}
export const parseError = (message = null) => {

 
    if(Array.isArray(message)) {
        if(message.length === 0) return null;
        return message.join(', ');
    } else if(typeof message === 'object') {
        return JSON.stringify(message, null, '\t');
    }

    return message;
}

export const parseAddress = (section = 'multisig', data = {}) => {
    return data && section === 'multisig' ? data[1][0] : data && section === 'multiCoin' && Array.isArray(data) ? data[0].value : Array.isArray(data[0]) ? data[0][0] : data[0].to_address ? data[0].to_address : data[0]
}
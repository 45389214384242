import React from 'react';
import { Link } from 'react-router-dom';
import commaNumber from 'comma-number';
import timeAgo from 'time-ago';
import { parseAddress } from '../../../utils/extrinsics';
import { timeAgoFormator } from '../../../utils/timeFormater';

export default ({ block_id, index, hash, signer, timestamp, data, method, section }) => (
    <div className="border-bottom d-flex justify-content-between mb-3">
        <div className="Text-Box">
            <div className="d-flex font-weight-bold Fsize_16">
                <label className="mb-0 mr-3">Extrinsic Index#</label>
                <Link to={`/extrinsic/${hash}`} > <a>{commaNumber(block_id)}-{index}</a></Link>
            </div>
            <div className="d-flex">
                <label className="mr-3">From</label>
                <Link data-toggle="tooltip" title={signer} className="WalletDots" to={`/account/${signer}`}>{signer}</Link>
                <label className="mx-3">To</label>
                <Link  data-toggle="tooltip" title={parseAddress(section, data)} className="mr-3 WalletDots" to={`/account/${ parseAddress(section, data) }`}>{ parseAddress(section, data) }</Link>
            </div>
        </div>
        <div className="d-flex align-items-center">
            <span>{ timeAgoFormator(timestamp) }</span>
            <i className="far fa-check-circle text-success ml-2 Fsize_20" />
        </div>
    </div>
)
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Extrinsics from './Extrinsics/Extrinsics';
import Block from './Block/Block';
import { connect } from 'react-redux';
import commaNumber from 'comma-number';
import { holdersCount } from '../../api/holder.js';

class Home extends Component {

    state = {
        highcharts  :   null,
        holder_count : null
    }

    holdersCountAPICall = async()=>{
        try {
            const response = await holdersCount();
            this.setState({ ...this.state, holder_count: response.data["walletCount"] })
        } catch(e) {}
    }    

    componentDidMount() {
        this.holdersCountAPICall()
    }

    

    render() {
        const { signedExtrinsicsCount, transfersCount, totalIssuance, holdersCount, nucoinUSDPrice } = this.props;

        return (
            <React.Fragment>
                <main className="mb-5">
                    <section className="mb-4" id="Statistics">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-4 mb-lg-0">
                                    <h5 className="mb-3"><i className="fas fa-chart-bar" /> Chain Data</h5>
                                    <div className="WhiteBox rounded p-4">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4">
                                                <i className="fas fa-th-large Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Latest Blocks
                                                <h5 className="pt-1">{ this.props.blocks.length > 0 && commaNumber(this.props.blocks[0].block_id) }</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4">
                                                <i className="fa fa-dollar-sign Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Price USD
                                                    <h5 className="pt-1">{ commaNumber(nucoinUSDPrice) }</h5>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4">
                                                <i className="fas fa-calculator Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Signed Extrinsics
                                                    <h5 className="pt-1">{ commaNumber(signedExtrinsicsCount) }</h5>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4">
                                                <i className="fas fa-exchange-alt Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Transfers
                                                    <h5 className="pt-1">{ commaNumber(transfersCount) }</h5>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4 mb-sm-0">
                                                <i className="fas fa-user Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Holders
                                                    <h5 className="pt-1">{ this.state.holder_count || 'N/A' }</h5>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4 mb-sm-0">
                                                <i className="fas fa-chart-pie Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Total Issuance / Circulation
                                                    <h5 className="pt-1">{ totalIssuance || 'N/A' }</h5>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center mb-4 mb-sm-0">
                                                <i className="fas fa-coins Fsize_20 mr-3" />
                                                <div className="TextBox">
                                                    Total Supply / Hard Cap
                                                    <h5 className="pt-1">4.9 Billion</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4">
                                    <h5 className="mb-3"><i className="fas fa-chart-bar" /> Network Stats</h5>
                                    <div className="WhiteBox px-3">
                                        <figure className="highcharts-figure">
                                            <div id="PieChart" />
                                        </figure>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    <section id="TableBlock">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h5 className="mb-0"><i className="fas fa-th-large align-middle Fsize_20 mr-3" /> Latest Blocks</h5>
                                        <Link className="btn BtnDark py-1 px-4" to={'/blocks'}>All</Link>
                                    </div>
                                    <div className="WhiteBox py-3 px-4">
                                        {
                                            this.props.blocks.map(block => <Block key={block.block_id} {...block}/>)
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h5 className="mb-0"><i className="fas fa-exchange-alt Fsize_20 mr-3" /> Transfers</h5>
                                        <Link className="btn BtnDark py-1 px-4" to={'/extrinsics'}>All</Link>
                                    </div>
                                    <div className="WhiteBox py-3 px-4">
                                        {
                                            this.props.extrinsics.map(extrinsic => {
                                                console.log(extrinsic);
                                                return <Extrinsics key={extrinsic.block_id} {...extrinsic} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    blocks                  :  state.Block.blocks,
    extrinsics              :  state.Extrinsics.extrinsics,    
    signedExtrinsicsCount   :  state.Block.signedExtrinsicsCount,    
    nucoinUSDPrice          :  state.Block.nucoinUSDPrice,    
    transfersCount          :  state.Block.transfersCount,    
    totalIssuance           :  state.Block.totalIssuance,
    holdersCount            :  state.Block.holdersCount
})


export default connect( mapStateToProps, {
})(Home);

import invoke, { doAxiosCall } from '../utils/invoke';
// import { USI_MINT_API_BASE,USI_WALLET_API_BASE,SVC_NUC_SERVICE_URL } from '../config.json';
import { USI_MINT_API_BASE,USI_WALLET_API_BASE,SVC_NUC_SERVICE_URL } from '../config.prod.json';
import { encrypt } from '../utils/security';
export const coinMintPossible = (address) => {
    return invoke({
        url: `${USI_MINT_API_BASE}/v1/bc/mint/lastMintV2/${address}`,
        method: 'GET'
    })
}


export const coinMint = (data) => {
    return invoke({
        url: `${USI_MINT_API_BASE}/v1/bc/mint/coinMintV2`,
        method: 'POST',
        data: data
    })
}

export const holdersCount = () => {
    return invoke({
        url: `${USI_WALLET_API_BASE}/v1/bc/wallet/count`,
        method: 'GET'
    })
}

export const getUserBalance = (address) => {
    return invoke({
        url: `${USI_WALLET_API_BASE}/v1/bc/wallet/balance/${address}`,
        method: 'GET'
    })
}

export const getPlatformValue = (toggleType) => {

    return invoke({
        // method: 'GET',
        // baseURL: SVC_NUC_SERVICE_URL,
        // route: `check_toggle_status?toggle_column=${toggleType}`,
        url: `${SVC_NUC_SERVICE_URL}/check_toggle_status?toggle_column=${toggleType}`,
        method: 'GET'

    });
}

export const doTransferBalance = (payload) => {
   
    let data = encrypt(payload);
    return doAxiosCall({
        method:     'POST',
        baseURL:    USI_WALLET_API_BASE,
        url: `${USI_WALLET_API_BASE}/v1/bc/wallet/transfer`,
        data,
        timeout: 60000,
        headers: {
            'Content-type': 'application/json; charset=utf-8'
         }
    });
    
}



import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import timeAgo from 'time-ago';
import moment from 'moment';

export default (props) => {
    
    const { block_id, hash, signer, to_address, section, method, timestamp, data, address, status, index, page = 0, timeTypeToggle = false } = props
    const [ timeToggle, setTimeToggle ] = useState(false);

    useEffect(() => {
        setTimeToggle(timeTypeToggle)
    }, [timeTypeToggle]);

     function getBalance(method,data) {
        
        console.log(method,data)
        const transections = JSON.parse(data ?? [])
        
        if(transections.length>0){
           
            switch (method)
            {
                case 'transfer' :
                   return transections[0].value
                case 'transferKeepAlive':
                   return transections[0].value
                case 'massTransfer':
                   return transections[0].value
                case 'generateSwapCoins':
                   return transections[0].value
                case 'burnSwapCoins':
                   return transections[0].value
                case 'burnCoins':
                    return transections[0].value
                // case 'asMulti':
                //     return JSON.stringify(props) 
                //     return
                default :
                   return "--"
            }
        }
        else{
            return '--'
        }
        
    }
 
    return (
        <tr>
            {/* <td><span className="RankCircle d-inline-block text-center rounded-circle bg-success text-white">{index + (page > 0 ? page * 20 : 0)}</span></td> */}
            <td ><Link data-toggle="tooltip" title={hash} className="WalletDots w-100" to={`/extrinsic/${hash}`}>{hash}</Link></td>
            {
                <td><Link data-toggle="tooltip" title={address === to_address ? signer : to_address }  className="WalletDots w-100" to={`/account/${address === to_address ? signer : to_address}`}>{address === to_address ? signer : to_address}</Link></td>
            }
            <td><i title={address === to_address ? 'Received':'Transfer'} style={{ color: address === to_address ?  'green' : 'red'  }} className={`fas fa-arrow-${address === to_address ? 'down': 'up' }`}></i></td>
            <td>{ method }</td>
            <td>{getBalance(method,data)}</td>
            <td>{ timestamp ? timeToggle ? moment(timestamp).format('lll') : timeAgo.ago(timestamp) : 'N/A' }</td>
            <td>{ status === 'ExtrinsicFailed' ? <i title="Errored" className="far fa-times-circle text-danger Fsize_20 pr-1 align-center" aria-hidden="true" /> : <i title="Success" className="far fa-check-circle text-success Fsize_20 pr-1 align-center" aria-hidden="true" /> }</td>
        </tr>
    )
}

import moment from "moment"
import timeAgo from 'time-ago';

 export const dateTimeFormator =  (timeStamp)=>{

    let formatedTime = moment(timeStamp.includes("GMT")  || timeStamp.includes("Z")? timeStamp : timeStamp+" GMT").format('lll');

    if(formatedTime == 'Invalid date') {
        formatedTime = moment(timeStamp.includes("GMT")  || timeStamp.includes("Z")? timeStamp : timeStamp+" Z").format('lll');
    }

    return formatedTime;
}

export const timeAgoFormator = (timeStamp)=>{

    let formatedTime = moment(timeStamp.includes("GMT") || timeStamp.includes("Z")? timeStamp : timeStamp+" GMT");

    if(timeAgo.ago(formatedTime) == 'NaN year ago') {
       formatedTime = moment(timeStamp.includes("GMT") || timeStamp.includes("Z")? timeStamp : timeStamp+" Z");
    }



    return timeAgo.ago(formatedTime);
}
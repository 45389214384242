import invoke from '../../utils/invoke';
import { SERVER_END_POINT } from '../../config.json';

export const getAccountDetail = (address) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/account/admin/detail/${address}`,
        method  :   'GET',
        headers: {
            'accessKey': localStorage.getItem('access-key'),
            'secretKey': localStorage.getItem('secret-key')
        }
    })
}

export const setTransferToggleCoinApi = (userName, toggleValue) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/account/admin/toggle/transfer/${userName}/${toggleValue}`,
        method  :   'POST',
        headers: {
            'accessKey': localStorage.getItem('access-key'),
            'secretKey': localStorage.getItem('secret-key')
        }
    })
}

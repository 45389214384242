import react, { useEffect, useState } from 'react';
import timeStamp from 'time-stamp';
import timeAgo from 'time-ago';
import commaNumber from 'comma-number';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default ({ block_id, parent_id, parent_hash, hash, extrinsics_count, events_count, timestamp, timeTypeToggle = false  }) => {

    const [ timeToggle, setTimeToggle ] = useState(false);

    useEffect(() => {
        setTimeToggle(timeTypeToggle)
    }, [timeTypeToggle]);

    return (
        <tr>
            <td><Link to={`/block/${block_id}`}>{ commaNumber(block_id) }</Link></td>
            <td><Link to={`/block/${parent_id}`}>{ commaNumber(parent_id) }</Link></td>
            <td><Link data-toggle="tooltip" title={parent_hash}  to={`/block/${parent_id}`} className="WalletDots" >{ parent_hash }</Link></td>
            <td><Link data-toggle="tooltip" title={hash} to={`/block/${parent_id}`} className="WalletDots" >{ hash }</Link></td>
            <td>{ extrinsics_count }</td>
            <td>{ events_count }</td>
            <td>{ timestamp ? timeToggle ? moment(timestamp).format('lll') : timeAgo.ago(timestamp): 'N/A' }</td>
            <td><i className="far fa-check-circle text-success Fsize_20" /></td>
        </tr>
    )
}
import React, { Component } from 'react';
import Transfers from '../Accounts/Transfers/Transfers';
import $ from 'jquery';
import 'datatables.net';
import { mnemonicValidate, validateAddress } from '@polkadot/util-crypto';
import { Keyring } from '@polkadot/keyring';
import SearchBar from '../Layout/SearchBar';
import Breadcrumb from '../Layout/Breadcrumb';
import bigDecimal from 'js-big-decimal';
import { connect } from 'react-redux';
import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';
import downloadAsJson from '../../utils/downloadAsJson';
import { doTransferBalance, getUserBalance, getPlatformValue } from '../../api/mintTranferBalance';

class TransferFunds extends Component {

    keyring = new Keyring({ type: 'sr25519' });
    pair    = null;

    state = {
        mnemonics: null,
        from: null,
        receiver: null,
        value: null,
        balance: null,
        message: {
            value: null,
            isErrored: false
        },
        isLoading: false,
        isReadyToTransfer: false,
        historians: [],
        isLocked: false
    }

    updateUserBalance(address){
        getUserBalance(address).then((balanceData)=>{
            this.setState({ ...this.state, balance: `${balanceData?.data?.balance}` });
           }).catch(function (error) {
               this.setState({ ...this.state, balance: 0 });
           });
    }
    on = e => {
        e.preventDefault();

        if(e.target.name === 'mnemonics' && mnemonicValidate(e.target.value)) {
            this.pair = this.keyring.addFromUri(e.target.value);
            this.setState({ ...this.state, [e.target.name]: e.target.value, from: this.pair.address });
            this.updateUserBalance(this.pair?.address);
        
        } else this.setState({ ...this.state, [e.target.name]: e.target.value });

    }


  
    async transferFunds(e) {
        e.preventDefault();
        if(!this.validateNumber(this.state.value) || !mnemonicValidate(this.state.mnemonics)) return;

        this.setState({ ...this.state, isLoading: true, isLocked: true, message: { value: null, isErrored: false } });

        let transferToggle = await getPlatformValue("toggle_transfer_coin")

        if (transferToggle?.data?.toggle_transfer_coin?.calculated_toggle === 1) {
            this.setState({ ...this.state, message: { value: <div>Transfer feature is not available until further notice, we apologize for any inconvenience. You will be notified by the NuCoin Admin team once it becomes available again.</div>, isErrored: true }, isLoading: false });
            return
        }

            doTransferBalance({"secret":this.state.mnemonics,"recipient":this.state.receiver, "amount":this.state.value }).then((responce)=>{
                    const fromAddress = this.state.from;
                    const data = responce?.data;
                    this.setState({ ...this.state, message: { value: <div>{data?.transferred?.status ?? ""}<a>{data?.transferred?.blockHash ?? ''}</a></div>, isErrored: false} });
                    if(data?.transferred?.status === 'ExtrinsicFailed') {
                        this.setState({ ...this.state, message: { value: <div>Transaction Failed! i.e. Balance too low <a>{data?.transferred?.blockHash ?? ''}</a> </div>, isErrored: true}, isLoading: false, isLocked: false, }, () => {
                            this.setState({ ...this.state, from: fromAddress });
                        });
                    } else {
                        this.setState({ ...this.state, message: { value: <div>Transaction Successfull! Tx Hash <a>{data?.transferred?.blockHash ?? ''}</a> </div>, isErrored: false}, isLoading: false }, () => {
                            this.setState({ ...this.state, from: fromAddress });
                            this.updateUserBalance(this.pair?.address)
                        
                        });
                    } 
                }).catch((e)=> {
                    this.setState({ ...this.state, message: { value: <div>{e.message}</div>, isErrored: true }, isLoading: false });

               });
                
    }

    componentDidMount() {
        this.registerJQuery();
    }

    registerJQuery = () => {
        function format(d) {
            // `d` is the original data object for the row
            return '<div class="WhiteBox lightGrey"><table cellpadding="5" cellspacing="0" border="0" class="w-100">' +
                '<tr>' +
                '<td>Docs</td>' +
                '<td>Transfer succeeded. \[from, to, value\]</td>' +
                '</tr>' +
                '<tr>' +
                '<td>AccountId</td>' +
                '<td><a href="#" id="sample">2bsRXVKED2pzB1xHgLRJm7PMufsTBDU8tmTrgJ9a8teYybNp</a><button type="button" class="btn p-0 ml-2 bg-transparent shadow-none" href="#" onclick="CopyToClipboard(\'sample\');return false;"><i class="far fa-copy"></i></button></td>' +
                '</tr>' +
                '<tr>' +
                '<td>AccountId</td>' +
                '<td><a href="#" id="sample2">2bsRXVKED2pzB1xHgLRJm7PMufsTBDU8tmTrgJ9a8teYybNpOO</a><button type="button" class="btn p-0 ml-2 bg-transparent shadow-none" href="#" onclick="CopyToClipboard(\'sample2\');return false;"><i class="far fa-copy"></i></button></td>' +
                '</tr>' +
                '<tr>' +
                '<td>Balance</td>' +
                '<td>8,135.9842 </td>' +
                '</tr>' +
                '</table></div>';
        };

        var table = $('#AccountPageTable').DataTable({
            "fnInfoCallback": function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                return '';//iStart + " - " + iEnd + " of " + iMax;
            },
            order: [1, 'asc'],
            lengthChange: false,
            pageLength: 10,
            info: true,
            paging: !true,
            ordering: false,
            autoWidth: false,
            language: {
                paginate: {
                    previous: '<i class="fas fa-arrow-left"></i>',
                    next: '<i class="fas fa-arrow-right"></i>',
                },
                sEmptyTable:
                    '<div>' +
                    '</div>',
            },
            initComplete: (settings, json) => {
                $('#AccountPageTable_info').appendTo('.AppendInfo');
                $('#AccountPageTable_paginate').appendTo('.AppendPagination');
            },
        });
    
        // Add event listener for opening and closing details
        $('#AccountPageTable tbody').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function CopyToClipboard(id) {
            var r = document.createRange();
            r.selectNode(document.getElementById(id));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(r);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
        }

        // $('.CustomDropDown').selectpicker();
    }
    validateSR588Address(address) {
        
        try {
            encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));
            // this.keyring.decodeAddress(address);
            return true;
          } catch (error) {
            return false;
          }
    }

    validateNumber(value) {
        try {
            const number = parseInt(value);
            return !Number.isNaN(number);

        } catch(e) {
            return false;
        }
    }

    render() {

        return (
	        <main class="mb-5">
                <Breadcrumb />
            <section id="SearchBox">
                <div className="row justify-content-between align-items-center mb-4">
                    <div className="container">
                        <SearchBar />
                    </div>
                </div>
            </section>
            <section id="TransferForm">
			<div className="container">
				<h5 className="mb-3"><i className="fas fa-chart-bar" aria-hidden="true"></i> Transfer</h5>
				<div className="WhiteBox rounded py-2 px-3 mb-4">
					<div className="row align-items-end">
                    <div className="col-sm-6 form-group">
                        <label for="Receiver">Mnemonics</label>
                        <input disabled={this.state.isLocked} onChange={this.on} value={this.state.mnemonics || ''} placeholder="Paste account mnemonics here" type="text" style={{ borderColor: this.state.mnemonics && mnemonicValidate(this.state.mnemonics) ? '' : this.state.mnemonics !== null ? 'red' : '' }} className="form-control shadow-none" name="mnemonics"/>
                    </div>
                    <div className="col-sm-6 form-group">

                        {
                            this.state.isLoading && this.state.message.value && <div style={{ marginBottom: '-7px' }} className="alert alert-info" role="alert">
                                { this.state.message.value }
                            </div>
                        }
                        
                        {
                            this.state.message.isErrored && <div style={{ marginBottom: '-7px' }} className="alert alert-danger" role="alert">
                                { this.state.message.value }
                            </div>
                        }
                        
                        {
                            this.state.message.value && !this.state.isLoading && !this.state.message.isErrored && <div style={{ marginBottom: '-7px' }} className="alert alert-success" role="alert">
                                { this.state.message.value }
                            </div>
                        }
                      
                    </div>
					</div>
					<div className="row align-items-end">

                        <div className="col-sm form-group">
                            <label for="mnemonics">From { this.state.from && <b style={{ color: !this.state.isLoading && this.state.message.value && !this.state.message.isErrored ? 'green' : 'black' }}>( {this.state.balance} NUC )</b>  }</label>
                            <input disabled value={this.state.from || ''} placeholder="----" type="text" className="form-control shadow-none"/>
                        </div>
                        <div className="col-sm-auto text-center form-group d-none d-sm-block">
							<i className="fas fa-exchange-alt fa-2x" aria-hidden="true"></i>
						</div>
						<div className="col-sm form-group">
							<label for="From">Recipient</label>
							<input disabled={this.state.isLocked}  onChange={this.on} name="receiver" style={{ borderColor: this.state.receiver && this.validateSR588Address(this.state.receiver) ? '' : this.state.receiver !== null ? 'red' : '' }} type="text" value={this.state.receiver || ''} placeholder="Recipient address" className="form-control shadow-none" id="From"/>
						</div>
						


						<div className="w-100"></div>
						<div className="col-sm-6 form-group">
							<label for="Values">Values</label>
                            <div className="row">
                                <div className="col-10">
                                    <input disabled={this.state.isLocked}  onChange={this.on} name="value" style={{ borderColor: this.state.value && this.validateNumber(this.state.value) ? '' : this.state.value !== null ? 'red' : '' }} type="text" value={this.state.value || ''} type="text" placeholder="Amount to transfer" className="form-control shadow-none" id="Values"/>
                                </div>
                                <div className="col-2">
                                    <b><label style={{ fontSize: '15px', marginTop: '7px', marginLeft: '-20px' }} className="text-secondary">NUC</label></b>
                                </div>
                            </div>
						</div>
                        <div className="col-sm-6 form-group">
                            <div className="row" style={{ paddingLeft: '26px' }}>
                                <div className="col-6">
                                    <button onClick={this.transferFunds.bind(this)} disabled={ this.state.isLocked || (this.state.isLoading === false ? (!this.validateNumber(this.state.value) || !this.validateSR588Address(this.state.receiver) || !mnemonicValidate(this.state.mnemonics)) : this.state.isLoading)} style={{ width: '100%' }} className="btn BtnDark px-5 font-weight-bold shadow-none">Transfer{ this.state.isLoading && <span className="fa fa-spinner fa-spin"/>}</button>
                                </div>
                                <div className="col-6">
                                    <button disabled={this.state.isLoading} onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            mnemonics: null,
                                            from: null,
                                            receiver: null,
                                            balance: null,
                                            value: null,
                                            message: {
                                                value: null,
                                                isErrored: false
                                            },
                                            isLoading: false,
                                            isReadyToTransfer: false,
                                            historians: [],
                                            isLocked: false
                                        })
                                    }} style={{ width: '100%' }} className="btn BtnDark px-5 font-weight-bold shadow-none">Refresh</button>
                                </div>
                            </div>
						</div>
                        {/* <div className="col-sm-3 form-group">
						    
						</div> */}
					</div>

                   
					{/* <div className="form-group">
						<button type="submit" className="btn BtnDark px-5 font-weight-bold shadow-none">Send</button>
					</div> */}
				</div>
			</div>
		</section>
        <div className="container">
            <div style={{ minHeight: '250px' }} className=" WhiteBox rounded p-4">
                <table className="Data_tables rounded mb-4" ref="AccountPageTableRef" id="AccountPageTable">
                    {
                        <Transfers address={this.state.from} setPagination={() => {}} setHistorian={(historians) => { this.setState({ ...this.state, historians: historians}) }} />
                    } 
                </table>
                {
                this.state.historians.length > 0 ? (
                        <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                            <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                downloadAsJson(this.state.historians, 'transactions');
                            }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                        </div>
                    ) : null
                }
            </div>
         
        </div>
        
		</main>
        )
    }
}

const mapStateToProps = state => ({
   
})

export default connect(mapStateToProps)(TransferFunds);
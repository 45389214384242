import  React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import timeAgo from 'time-ago';

export default ({ hash, block_id, method, section, index, data, isExpaned = false }) => {

    const [ isShown, setShown ] = useState(false);

    useEffect(() => {
        setShown(isExpaned);
    }, [ isExpaned ]);

    return (
        <React.Fragment>
            <tr className={ isShown ? 'shown' : '' }>
                <td><Link to={`/block/${block_id}`}>{block_id}-{index}</Link></td>
                <td><a>{section}</a></td>
                <td><a>{ method }</a></td>
                <td className="details-control">
                    <button onClick={e => setShown(!isShown)} type="button" className="btn BtnDark shadow-none py-0 px-2"><i
                            className="fas fa-chevron-right"></i></button>


                </td>
            </tr>  
            <tr style={{ display: isShown ? '' : 'none' }} >
                <td colspan="4">
                    <div className="WhiteBox lightGrey">
                        <table className="w-100" cellspacing="0" cellpadding="5" border="0">
                            <tbody>
                                {
                                    JSON.parse(data || [] ).map((value, index) => (
                                        <tr>
                                            <td>{ index }</td>
                                            <td>{ JSON.stringify(value || {}) }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </React.Fragment>

    )
}
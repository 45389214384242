import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';

import Home from './components/Home/Home';
import BlockDetails from './components/Blocks/BlockDetails';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import ExtrinsicDetails from './components/Extrinsics/ExtrinsicDetails';
import TransactionDetails from './components/Extrinsics/TransactionDetails';
import Blocks from './components/Blocks/Blocks';
import Extrinsics from './components/Extrinsics/Extrinsics';
import Accounts from './components/Accounts/Accounts';
import TransferFunds from './components/TransferFunds/TransferFunds';
import MintCoin from './components/MintCoin/MintCoin'
export default class App extends Component {

  state = {
    path    : window.location.pathname,
    isHome  : window.location.pathname === '/'
  }

  headerRef = null;

  locationChangeListener = () => {
    if(window.location.pathname === '/') {
      if(this.state.path !== '/') this.setState({ isHome  : true, path: window.location.pathname });
    } else if(window.location.pathname !== '/'){
      if(this.state.path === '/') this.setState({ isHome  : false, path: window.location.pathname });
    }
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <div onClick={() => this.locationChangeListener()} className="wrapper">
              <Route exact path="*" component={Header}/>
              <Route exact path="/" component={Home} />
              <Route exact path="/block/:block_id" component={BlockDetails} />
              <Route exact path="/extrinsic/:hash" component={ExtrinsicDetails} />
              <Route exact path="/blocks" component={Blocks} />
              <Route exact path="/extrinsics" component={Extrinsics} />
              <Route exact path="/account/:address" component={Accounts} />
              <Route exact path="/transfer/fund" component={TransferFunds} />
              <Route exact path="/transactiondetail" component={TransactionDetails} />
              <Route exact path="/coin/mint" component={MintCoin} />
              {/* <Route exact path="/transfer" component={Transfer} />
              <Route exact path="/accounts" component={Accounts} />
              <Route exact path="/block" component={BlockDetails} />
              <Route exact path="/events" component={Events} />
              <Route exact path="/extrinsics" component={Extrinsics} />
              <Route exact path="/extrinsic" component={ExtrinsicDetails} />
  
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/tools" component={Charts} /> */}
              <Footer isHome={window.location.pathname === '/'} />
            </div>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

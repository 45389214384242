import React, { Component } from 'react';
import { getOtherExtrinsics } from '../../../api/account';
import Pagination from '../../Layout/Pagination';
import ListItem from './ListItem/ListItem';
import Loader from '../../Layout/Loader';

export default class OtherExtrinsics extends Component {

    state = {
        data    :   [],
        totalItem:0,
        meta    :   {
            pages   :   0,
            currentPage: 0 
        },
        isLoading   :   true,
        isExpaned   : false,
        timeTypeToggle   : false,
    }

    componentDidMount() {
        this.invokeOtherExtrinsicsList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.address !== prevProps.address) {
            this.setState({
                ...this.state,
                data    :   [],
                meta    :   {
                    pages   :   0,
                    currentPage: 0 
                }
            }, this.componentDidMount);
        }

        if (this.props.isRefresing !== prevProps.isRefresing) {
            if(this.props.isRefresing)
                this.invokeOtherExtrinsicsList();
        }
    }

    invokeOtherExtrinsicsList = async (page = 0) => {
        this.setState({ ...this.state, isLoading: true }, async () => {
            try {
                const { data,total } = await getOtherExtrinsics(this.props.address, page);
                this.setState({ ...this.state, ...data,totalItem:total , isLoading: false }, () => {
                    this.props.setPagination(
                        <Pagination page={page + 1} count={Math.round(this.state.total/20)+1} onChangeListener={(page) => this.invokeOtherExtrinsicsList(page - 1)} />
                    )
                });
            } catch(e) {}
        })
    }

    render() {

        const { data, isLoading, isExpaned, timeTypeToggle } = this.state;

        return (
            <React.Fragment>
                <thead>
                    <tr>
                        <th width="15%">Hash</th>
                        <th width="15%">Section</th>
                        <th width="15%">Mehtod</th>
                        <th width="15%">Time <i onClick={() => this.setState({ ...this.state, timeTypeToggle: !timeTypeToggle }) } style={{ cursor: 'pointer', fontSize: '12px', color: '#58595a' }} className={`fa${ timeTypeToggle ? 's' : 'r' } fa-clock`}></i></th>
                        <th width="10%">Status</th>
                        <th width="15%">Data <i onClick={() => this.setState({ ...this.state, isExpaned: !isExpaned }) } style={{ cursor: 'pointer' }} className={`fas fa-caret-${ isExpaned ? 'up' : 'down' }`}></i></th>
                    </tr>
                </thead>
                <tbody ref="AccountPageTableBodyRef">
                   { data.map( (extrinsic, index) => <ListItem key={extrinsic.block_id} {...extrinsic} isExpaned={isExpaned} timeTypeToggle={timeTypeToggle} index={index + 1} /> ) }
                </tbody>
                <div className="container-fluid"> { isLoading && <Loader /> }</div>
                { data.length === 0 && !isLoading && <label style={{ marginTop: '20px' }} className="text-secondary text-center">No Record Found</label> }
            </React.Fragment>
        )
    }

}
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setBlockList, setSignedExtrinsicsCount, setTotalIssuance, setTransfersCount, setHoldersCount, setNucoinUSDPrice } from '../../actions/block';
import { setExtrinsicsList } from '../../actions/extrinsics';
import { findPathIfExist } from '../../api/search';
import Loader from './Loader';
import Logo from './assets/logo.png';
class Header extends Component {

    state = {
        search  :   null,
        isRedirect  :   null,
        param       :   null,
        isSearching :    false,
        onchange:   (value) => this.setState({ ...this.state, search: value })
    }

    componentDidMount() {

        this.props.socket.on('new blocks', (blocks) => {
            this.props.setBlockList(blocks);
        });
        
        this.props.socket.on('new extrinsics', (extrinsics) => {
            console.log('tester extrinsic ', extrinsics)
            this.props.setExtrinsicsList(extrinsics);
        })
        
        this.props.socket.on('update signed extrinsics count', ({ count }) => {
            this.props.setSignedExtrinsicsCount(count);
        })
        
        this.props.socket.on('update transfers count', ({ count }) => {
            this.props.setTransfersCount(count);
        })
        
        this.props.socket.on('update total issuance', (totalIssuance) => {
            this.props.setTotalIssuance(totalIssuance);
        })
        
        this.props.socket.on('update holders count', ({ count }) => {
            this.props.setHoldersCount(count);
        })
        
        this.props.socket.on('nucoin usd price update', ({ price }) => {
            this.props.setNucoinUSDPrice(price);
        })

    }
    
    componentWillUnmount() {
        this.props.socket.off('new blocks');
        this.props.socket.off('new extrinsics');
        this.props.socket.off('update signed extrinsics count');
        this.props.socket.off('update transfers count');
        this.props.socket.off('update total issuance');
        this.props.socket.off('update holders count');
    }

    searchHandler = (e) => {
        e && e.preventDefault();
        this.setState({ ...this.state, isSearching: true }, async () => {
            try {
                const { data: { redirect, param } } = await findPathIfExist(this.state.search);
                this.setState({ ...this.state, search: '', isRedirect: redirect, param }, () => {
                    this.setState({ ...this.state, search: null, isRedirect: null, param: null });
                });
            } catch(e) {
    
            }
            this.setState({ ...this.state, isSearching: false });
        })
      
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            this.searchHandler();
        }
    }

    render() {
        const { match: { url } } = this.props;
        const { isSearching } = this.state;
        return (
            <React.Fragment>
                <header className="mb-4">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light px-0">
                            <Link to={'/'} > <a className="navbar-brand" href="home"><img className="img-fluid" src={Logo} style={{ width: 173, height: 83, tintColor: "red" }}/></a></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse rounded px-3 px-lg-0 pb-3 pb-lg-0 pt-2 pt-lg-0 mt-3 mt-lg-0" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto mr-2 font-weight-bold">
                                    <li className="nav-item">
                                        <Link to={'/'} > <a className="nav-link" aria-current="page" href="home">Home</a></Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="javascripto:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Blockchain
                </a>
                                        <div className="dropdown-menu pt-0 mt-0" aria-labelledby="navbarDropdown">
                                            <Link to={'/blocks'}><a className="dropdown-item" href="blocks">Blocks</a></Link>
                                            <Link to={'/extrinsics'}>  <a className="dropdown-item" href="extrinsics">Extrinsics</a></Link>
                                            <Link to={'/transfer/fund'}>  <a className="dropdown-item" href="extrinsics">Transfer Funds</a></Link>
                                            <Link to={'/coin/mint'}>  <a className="dropdown-item" href="extrinsics">Mint Coin</a></Link>
                                            {/* <a style={{ cursor: 'not-allowed' }} className="dropdown-item">Transfers</a> */}
                                            <a style={{ cursor: 'not-allowed' }} className="dropdown-item">Events</a>

                                        </div>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a style={{ cursor: 'not-allowed' }} className="nav-link">Accounts</a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a style={{ cursor: 'not-allowed' }} className="nav-link">Tools</a>
                                    </li>
                                </ul>
                                <div className="d-inline-flex">
                                    {/* <select className="form-control mr-2 CustomDropDown AfterNone LanguageDropDown">
                                        <option data-content="<i class='fas fa-globe-americas mr-2'></i>English" />
                                        <option data-content="<i class='fas fa-globe-americas mr-2'></i>Dutch" />
                                        <option data-content="<i class='fas fa-globe-americas mr-2'></i>Chinese" />
                                    </select> */}
                                    <select className="form-control CustomDropDown">
                                        <optgroup label="Mainnet">
                                            <option style={{ cursor: 'not-allowed' }}>Mainnet </option>
                                        </optgroup>
                                        <optgroup  disabled label="Testnet">
                                            <option sel style={{ cursor: 'not-allowed' }}>Testnet </option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </nav>
                        <div style={{ display: url !== '/' ? 'none' : '' }} className="row justify-content-center py-4">
                            <div className="col-lg-7 col-md-10">
                                {/* <form action> */}
                                    <div className="SearchBox input-group flex-column flex-sm-row mb-3 " >
                                        <div className="input-group-prepend h-45px">
                                            <select data-width="100px" className="form-control CustomDropDown h-45px">
                                                <option>All</option>
                                                {/* <option>Block</option>
                                                <option>Account</option>
                                                <option>Event</option> */}
                                            </select>
                                        </div>
                                        <input onChange={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            this.state.onchange(e.target.value)
                                        }} onKeyDown={this.handleKeyPress} type="text" value={this.state.search} className="form-control py-2 h-45px shadow-none" placeholder="Search by Block / Extrinsic / Account" />
                                        <div className="input-group-append">
                                            <button disabled={isSearching} onClick={this.searchHandler} className="btn BtnDark px-4 w-100 font-weight-bold h-45px" type="button">Search { isSearching && <Loader/> }</button>
                                        </div>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </header>
                { this.state.isRedirect && <Redirect to={`/${this.state.isRedirect}/${this.state.param}`} /> }
        </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    socket  :   state.Socket.connection
});

export default connect(mapStateToProps, {
    setBlockList                    :   setBlockList,
    setExtrinsicsList               :   setExtrinsicsList,
    setSignedExtrinsicsCount        :   setSignedExtrinsicsCount,
    setTransfersCount               :   setTransfersCount,
    setTotalIssuance                :   setTotalIssuance,
    setHoldersCount                 :   setHoldersCount,
    setNucoinUSDPrice               :   setNucoinUSDPrice,
})(Header);

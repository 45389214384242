import React, { Component } from 'react';
import { getExtrinsicByHash } from '../../api/extrinsic';
import { getNftPath } from '../../api/nftPath';
import Loader from '../Layout/Loader';
import { Link } from 'react-router-dom';
import hex2ascii from 'hex2ascii';
import EventsItem from './EventsItem/EventsItem';
import SearchBar from '../Layout/SearchBar';
import $ from 'jquery';
import 'datatables.net';
import downloadAsJson from '../../utils/downloadAsJson';
import { parseError } from '../../utils/extrinsics';
import { renderCopyButton } from '../../utils/copyText';
import Breadcrumb from '../Layout/Breadcrumb';
import _,{startCase, camelCase} from 'lodash'
// import Skeleton from 'react-loading-skeleton';
import { Lightbox } from "react-modal-image";
import {NFT_BACKEND_API} from '../../config.json'
import Modal from 'react-modal';
import getContractName from '../../utils/getContractName';
const moment = require('moment');

const UNIT_PRICE = 'NUC';
class ExtrinsicDetails extends Component {


   trasactionsDisplayLimit = 10;
    state = {
        extrinsic       : {},
        notFound    : false,
        isExpaned   : false,
        showEvmModal:false,
        nftPaths:{},
        evmToShow:null,
        evmType:null,
        trasactionsOffset:this.trasactionsDisplayLimit
    }
   
    componentDidMount(hash) {

        this.setState({ ...this.state, extrinsic: {} }, async () => {
            try {
                const response = await getExtrinsicByHash(hash || this.props.match.params.hash);
               
                this.setState({ ...this.state, extrinsic: response.data });
            } catch(e) {
                this.setState({ ...this.state, notFound: true });
            }
        });
        this.registerJQuery();
    }

    registerJQuery = () => {
        function format(d) {
            // `d` is the original data object for the row
            return '<div class="WhiteBox lightGrey"><table cellpadding="5" cellspacing="0" border="0" class="w-100">' +
    
                '<td>Data</td>' +
                '<td>0xa2f9f04c238140c0ebe96fe795bad6fa35937553cacc187ec6a8385afd8e5840</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Engine</td>' +
                '<td>1601662832</td>' +
                '</tr>' +
                '</table></div>';
        }
    
        var table = $('#LogTable').DataTable({
            "fnInfoCallback": function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                return '';
            },
            order: [1, 'asc'],
            lengthChange: false,
            pageLength: 10,
            info: true,
            paging: true,
            ordering: false,
            autoWidth: false,
            language: {
                paginate: {
                    previous: '<i class="fas fa-arrow-left"></i>',
                    next: '<i class="fas fa-arrow-right"></i>',
                },
                sEmptyTable:
                    '<div>' +
                    '</div>',
            },
            initComplete: (settings, json) => {
                $('#LogTable_info').appendTo('.AppendInfo');
                $('#LogTable_paginate').appendTo('.AppendPagination');
            },
        });

        // Add event listener for opening and closing details
        // $('#LogTable tbody').on('click', 'td.details-control', function () {
        //     var tr = $(this).closest('tr');
        //     var row = table.row(tr);
        //     console.log('tester ', tr.children()[tr.children().length - 1])

        //     // console.log('tester ', row))
        //     if (tr.hasClass('shown')) {
        //         // This row is already open - close it
        //         row.child.hide();
        //         tr.removeClass('shown');
        //     } else {
        //         // Open this row
        //         row.child(format('')).show();

                

        //         // tr.children()[tr.children().length - 1].(format(''));
        //         tr.addClass('shown');
        //     }
        // });
    
        //Copy Field
        // function CopyToClipboard(id) {
            // var r = document.createRange();
            // r.selectNode(document.getElementById(id));
            // window.getSelection().removeAllRanges();
            // window.getSelection().addRange(r);
            // document.execCommand('copy');
            // window.getSelection().removeAllRanges();
        // }
    }

    copyToClipboard(id) {
        var r = document.createRange();
        r.selectNode(document.getElementById(id));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }

    isVideo(type) {

        return RegExp('video|VIDEO|mp4|MP4|MOV|mov').test(type);

    }
     getNftUriPathCall(key,value){
       
    
        if(this.state.nftPaths && !_.isEmpty(this.state.nftPaths))
        {
            return this.state.nftPaths[key + value]
        }
        else
        {
           (async () => {
            try{
                    const response = await getNftPath(key,value);
               
                    this.setState({ ...this.state, nftPaths: {...this.state.nftPaths,[key + value]:response.data}});
                }catch(error){
                    this.setState({ ...this.state, nftPaths: {...this.state.nftPaths,[key + value]:{}}});
                }
            })()
        
           
        }
    }

    getEVMAppovalOpertorJSX(key, item)
    {
        return  (
            <div className="row pb-3"  >
                <div className="col-md-3">
                    <strong>{startCase(camelCase(getContractName(item[key]._operator)))}</strong>
                </div>
                <div className="col-md-9" style={{'word-break': 'break-all'}}>
                    {item[key]._operator}
                </div>
            </div>
            )
    }
    getEVMInitJSX(key, item)
    {
        return  (
            <div className="row pb-3"  >
                <div className="col-md-3">
                    <strong>{startCase(camelCase(key))}</strong>
                </div>
                <div className='TransactionValue col-md-9' style={{'word-break': 'break-all'}}> {item[key]} </div>
            </div>
            )
    }
    getEVMOrderJSX(key, item)
    {
        let inputObject = item[key];
        return Object.keys(inputObject).map(inputKey => {
            let value = inputObject[inputKey];
            let title = startCase(camelCase(inputKey));
            if(inputKey === 'price')
                value = inputObject[inputKey]/ Math.pow(10, 18) + " " +UNIT_PRICE;
            else if(inputKey === 'royalityPercent')
                value = inputObject[inputKey]/ Math.pow(10, 2) +"%";
            else if(inputKey === 'isOpenForBids' ){
                value = inputObject[inputKey]? "Open for bids" : "Timed Auction"
                title = 'Type'
            
            }
            else if(inputKey === 'startTime' &&  inputObject[inputKey] == '')
                return (<></>)
            else if(inputKey === 'deadline' &&  inputObject[inputKey] == '')
                return (<></>)
            return (
            <div className="row pb-3"  >
                <div className="col-md-3">
                    <strong>{title}</strong>
                </div>
                <div  className="col-md-9" style={{'word-break': 'break-all'}}>
                    { value && typeof value === 'object' ? JSON.stringify(value) : value ?? '--'}
                </div>
            </div>)})
        
    }

    getEVMNFTJSX(key, item)
    {
        let inputObject = item[key];
        return Object.keys(inputObject).map(inputKey => {
            let value = inputObject[inputKey];
            let title = startCase(camelCase(inputKey));
            if(inputKey === '_uri')
                return (<></>) 
            else if(inputKey === '_to')
                return (<></>) 
           
            return (
            <div className="row pb-3"  >
                <div className="col-md-3">
                    <strong>{title}</strong>
                </div>
                <div  className="col-md-9" style={{'word-break': 'break-all'}}>
                    { value && typeof value === 'object' ? JSON.stringify(value) : value ?? '--'}
                </div>
            </div>)})
        
    }
    getTrasectionsJSX(section,item){
        if(section == 'evm'){
           
            let nftType = null
            let nftPath = null
            if(item?.input?._payload)
            {
                let uriObject = this.getNftUriPathCall("payload",item?.input?._payload)
                nftType = uriObject ? uriObject.resource_type : null
                nftPath = uriObject ? uriObject.url : null
            }
            else if(item?.input?.tokenId){
              
                let uriObject = this.getNftUriPathCall("token_id",item?.input?.tokenId)
                nftType = uriObject ? uriObject.resource_type : null
                nftPath = uriObject ? uriObject.url : null
            }
            else if(item?.input?._tokenId){
              
                let uriObject = this.getNftUriPathCall("token_id",item?.input?._tokenId)
                nftType = uriObject ? uriObject.resource_type : null
                nftPath = uriObject ? uriObject.url : null
            }
            else if(item?.input?.orderNumber){
              
                let uriObject = this.getNftUriPathCall("order_id",item?.input?.orderNumber)
                nftType = uriObject ? uriObject.resource_type : null
                nftPath = uriObject ? uriObject.url : null
            }
            
           
            let nftJSX = (
                <div className="image" style={{
                    // border: '1px solid #dddddd',
                    // borderRadius:5,
                    // width: "20%",
                    // height:100,
                    // backgroundColor:'#eeeeee',
                    marginRight:10
                }}  onClick={()=> { this.setState({ ...this.state, showEvmModal: true, evmType:nftType, evmToShow:nftPath }) }}>
                {
                    this.isVideo(nftType) ?  <video style={{ height: 100, width: 100,borderRadius:5, border: '1px solid #dddddd' }} autoPlay className="w-100 rounded" loop muted>
                                                                <source src={nftPath ? NFT_BACKEND_API.concat(nftPath) : ''}/>
                                                            </video> :
                    <img style={{ height:100, width: 100 ,borderRadius:5, border: '1px solid #dddddd',}} src={nftPath ? NFT_BACKEND_API.concat(nftPath) : ''} />

                }
                </div>
            )
            let jsx =  Object.keys(item).map(key =>{ 

                let title = key;
                if(key === 'gasLimit' || key === 'gasPrice')
                    return<></>
                else if(key === 'source')
                    title = 'Sender'
                else if(key === 'value' && item[key] === '0')
                    return <></>
                else if(key === 'target')
                    title =  getContractName(item[key])
                
                else if(key === 'input' && item[key]._operator )
                    return this.getEVMAppovalOpertorJSX(key,item)
                else if(key === 'init')
                    return this.getEVMInitJSX(key,item)
                 
                else if(key === 'input' && item[key].orderNumber)
                    return this.getEVMOrderJSX(key,item)
                else if(key === 'input' && item[key]._uri)
                    return this.getEVMNFTJSX(key,item)
                else if(key === 'input' && item[key]._tokenId)
                    return this.getEVMNFTJSX(key,item)

                return  (
                    <div className="row pb-2" >
                        <div className="col-md-3">
                            <strong>{startCase(camelCase(title))}</strong>
                        </div>
                        <div className="col-md-9" style={{'word-break': 'break-all'}}>
                            {key==='to_address' ? <a href='#'>{item[key]}</a> : 
                                item[key] && typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key] ?? '--'}
                             
                        </div>
                    </div>
                    )

            }) 

            return <div className='d-flex'>{nftPath ? nftJSX : <></>}<div>{jsx}</div></div>;
    
        }
        else if(item.method === 'asMulti' || item.method === 'approveAsMulti') {

                let transections = item?.transections?.map(multisigTxn=><div style={{'word-break': 'break-all'}}>{this.getTrasectionsJSX(multisigTxn.section,multisigTxn)}<div style={{width:'100%',height:1,backgroundColor:'#eeeeee', marginTop:5, marginBottom:5}}/></div>)
                
            
                let jsx =  Object.keys(item).map(key => {
                    if(key === 'call')
                        return <></>
                    else if(key == 'transections')
                        return  (<div className="row pb-2" >
                                <div className="col-md-2">
                                    <strong>Transactions</strong>
                                </div>
                                <div className="col-md-10 ">
                                    <div>{transections}</div> 
                                </div>
                            </div>
                    )
                    return (<div className="row pb-2" >
                        <div className="col-md-2">
                            <strong>{startCase(camelCase(key))}</strong>
                        </div>
                        <div className="col-md-10 " style={{'word-break': 'break-all'}}>
                            {key === 'otherSignatories' ? item[key].map((item)=> <><span>{item}</span> <br/></>)
                            : (item[key] &&  typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key] ?? '--')} 
                                
                        </div>
                    </div>
                    )
                })
               
                return <>{jsx }</>;
            
           
        }
        else 
        {
            let jsx =  (Object.keys(item).map((key, index) => <><span><strong>{startCase(camelCase(key))}:</strong></span> <span>{key==='to_address' ? <Link id={item[key]} className="break-all" to={`/account/${item[key]}`}>{item[key]}</Link> : 
            typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key]}</span>{ Object.keys(item).length === (index + 1) ? '' : ','}&nbsp;&nbsp; </>) )

            return <>{jsx}</>;
        }
    }

    render() {

        const { hash, block_id, method, section, index, data, is_signed, signer, nonce, timestamp, events, transactions } = this.state.extrinsic;
        const { isExpaned } = this.state;

        return (
            <main className="mb-5">
                <Breadcrumb />
                <section id="SearchBox">
                    <div className="container">
                        <div className="row justify-content-between align-items-center mb-4">
                            <div className="col-lg-3 d-flex align-items-center">
                                <h4 className="mb-0">Extrinsic# { block_id }-{ index }</h4>
                            </div>
                            <div className="col-lg-5">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </section>
              
                <section id="BlockDetailPage">
                    <div className="container">
                        <div className="WhiteBox rounded py-3 px-4 mb-4">
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Time</label>
                                </div>
                                <div className="col-md-10">
                                    { timestamp ? moment(timestamp).format('YYYY-MM-DD hh:mm:ss A') : <Loader /> }
            </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label>Block</label>
                                </div>
                                <div className="col-md-10">
                                   <Link to={`/block/${block_id}`}><a>{ (<React.Fragment>{ block_id } <i className="far fa-check-circle text-success Fsize_20 pr-1 align-center" aria-hidden="true" /></React.Fragment>) || <Loader/>}</a></Link>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Extrinsic Hash</label>
                                </div>
                                <div className="col-md-10">
                                    <a className="break-all" id="sample">{ hash || <Loader/>}</a>
                                    <button type="button" className="btn p-0 ml-2 bg-transparent shadow-none" onclick="CopyToClipboard('sample');return false;"><i className="far fa-copy" />
                                    </button>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Module</label>
                                </div>
                                <div className="col-md-10">
                                    <a className="btn btn-success" style={{ color: 'white' }}>{ section || <Loader/> }</a>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Call</label>
                                </div>
                                <div className="col-md-10">
                                    <a className="btn btn-light" >{ method || <Loader/> }</a>
                                </div>
                            </div>
                            {
                                is_signed ? (
                                    <React.Fragment>
                                        <div className="row pb-2">
                                            <div className="col-md-2">
                                                <label className="fw-500 mb-1 md-sm-0">Sender</label>
                                            </div>
                                            <div className="col-md-10">
                                                <Link id={signer} className="break-all" to={`/account/${signer}`}>{ signer || <Loader/>  }</Link>
                                                { renderCopyButton(signer) }
                                            </div>
                                        </div>
                                        <div className="row pb-2">
                                            <div className="col-md-2">
                                                <label className="fw-500 mb-1 md-sm-0">Transactions</label>
                                            </div>
                                            <div className="col-md-10">

                                                <div>  
                                                    {
                                                        data && data.slice(0,this.state.trasactionsOffset).map((item, index) => <div style={{marginBottom:10}}>{this.getTrasectionsJSX(section,item)}<div style={{width:'100%',height:1,backgroundColor:'#eeeeee', marginTop:5, marginBottom:5}}/></div> )
                                                    }
                                                     {/* <span><strong><Link className="break-all" to={`/transactiondetail`} data={data}>View More</Link></strong></span> */}
                                                   
                                                   {data && data.length >=this.state.trasactionsOffset && <div  style={{cursor:'pointer'}} onClick={()=>{this.setState({...this.state,trasactionsOffset: this.state.trasactionsOffset+this.trasactionsDisplayLimit})}}><a href="#" style={{pointerEvent:'none'}}><strong>View More</strong></a> </div>}
                                                    
                                                </div>
                                     
                                            </div>
                                        </div>
                                        {
                                            section !== 'sudo' && section !== 'utility' && (
                                                <React.Fragment>
                                                    <div className="row pb-2">
                                                        {/* <div className="col-md-2">
                                                            <label className="fw-500 mb-1 md-sm-0">Destination</label>
                                                        </div> */}
                                                        {/* <div className="col-md-10"> */}

                                                            {/* TODO */}
                                                            {/* <Link id={data && section === 'multisig' ? data[1][0] : Array.isArray(data[0]) ? data[0][0] : data[0]} className="break-all" to={`/account/${ data && section === 'multisig' ? data[1][0] : Array.isArray(data[0]) ? data[0][0] : data[0] }`}>{  data && section === 'multisig' ? data[1][0] : Array.isArray(data[0]) ? data[0][0] : data[0] || <Loader/>  }</Link>
                                                            { renderCopyButton(data && section === 'multisig' ? data[1][0] : Array.isArray(data[0]) ? data[0][0] : data[0]) } */}
                                                        {/* </div> */}
                                                    </div>
                                                    {
                                                        // method === 'massTransfer' ? (
                                                        //     <React.Fragment>
                                                        //         {
                                                        //             data && data[0] && data[0].slice(1).map(address => (
                                                        //                 <div className="row pb-2">
                                                        //                     <div className="col-md-2">
                                                        //                         {/* <label className="fw-500 mb-1 md-sm-0">Destination</label> */}
                                                        //                     </div>
                                                        //                     <div className="col-md-10">
                                                        //                         <Link id={address} className="break-all" to={`/account/${ address }`}>{ address || <Loader/>  }</Link>
                                                        //                         { renderCopyButton(address) }
                                                        //                     </div>
                                                        //                 </div>
                                                        //             ))
                                                        //         }
                                                        //     </React.Fragment>
                                                            
                                                        // ) : null
                                                    }
                                                </React.Fragment>
                                            )
                                        }

                                    
                                        
                                    </React.Fragment>
                                    
                                ) : null
                            }
                            <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Nonce</label>
                                </div>
                                <div className="col-md-10">
                                    { nonce }
                                 </div>
                            </div>
                            
                            <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Result</label>
                                </div>
                                
                                <div className="col-md-10">
                                    {
                                        events && events[index] && events[index].method == 'ExtrinsicFailed' ? (
                                            <React.Fragment>
                                                <i className="far fa-times-circle text-danger Fsize_20 pr-1 align-center" aria-hidden="true" /> Failed  { events && events[index] && parseError(events[index].error_status) && `( ${ parseError(events[index].error_status) || 'N/A' } )` } 
                                            </React.Fragment>
                                            
                                        )   :   (
                                            events && (
                                                <React.Fragment>
                                                    <i className="far fa-check-circle text-success Fsize_20 pr-1 align-center" aria-hidden="true" /> Success
                                                </React.Fragment>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            
                            {/* <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Transactions</label>
                                </div>
                                <div className="col-md-10">
                                    <div className="WhiteBox white">
                                        <div className="table-responsive">
                                       
                                            <div className="container">
                                           
                                                { transactions && transactions.map((value, index) => <TransactionItem transaction={value} />) }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/*<div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Parameters</label>
                                </div>
                                <div className="col-md-10">
                                    <div className="WhiteBox lightGrey">
                                        <div className="table-responsive">
                                            <div className="container">
                                                {/* { data && data.map((value, index) => (<div className="row" style={{ width: '100%' }}>{index}{' - '}{typeof value === 'object' ? JSON.stringify(value, null, '\t') : `${value}` }</div>)) } 

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            {/* <div className="row pb-2">
                                <div className="col-md-2">
                                    <label className="fw-500 mb-1 md-sm-0">Signature</label>
                                </div>
                                <div className="col-md-10">
                                    <div className="WhiteBox lightGrey">
                                        <table cellPadding={5} cellSpacing={0} border={0} className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td className="break-all">
                                                        3af06e2d47efe7b13add30cd3024280440c535ed59b225698db7721659c7c51665e14f196b40809ccf9bb11681b0bbea7de9a32bf2bea5bcb6c2d80ff4082c81
                        </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="WhiteBox rounded py-3 px-4 mb-4">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="Events-tab" data-toggle="tab" href="#Events" role="tab" aria-controls="Events" aria-selected="true">Events { events && events.length }</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="Events" role="tabpanel" aria-labelledby="Events-tab">
                                    <table id="LogTable" className="Data_tables rounded mb-4">
                                        <thead>
                                            <tr>
                                                <th width="25%">Event ID</th>
                                                <th width="25%">Section</th>
                                                <th width="25%">Method</th>
                                                <th width="25%">Data <i onClick={() => this.setState({ ...this.state, isExpaned: !isExpaned }) } style={{ cursor: 'pointer' }} className={`fas fa-caret-${ isExpaned ? 'up' : 'down' }`}></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { events && events.map((event, index) => <EventsItem key={index} {...event} isExpaned={isExpaned} />) }
                                        </tbody>
                                    </table>
                                    {
                                        events && events.length > 0 ? (
                                            <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                                <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                                    if(events.length > 0) downloadAsJson(events, 'events');
                                                }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                                            </div>
                                        ) : null
                                    }
                                    
                                    {/* <div className="row align-items-center justify-content-end gx-5">
                                        <div className="col-md-auto AppendInfo text-center text-md-right mb-3 mb-md-0" />
                                        <div className="col-md-auto AppendPagination text-center text-md-right" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                  
                    this.state.showEvmModal && !this.isVideo(this.state.evmType) && (
                        <Lightbox
                            medium={ NFT_BACKEND_API.concat(this.state.evmToShow ?? '') }
                            large= {NFT_BACKEND_API.concat(this.state.evmToShow ?? '') }
                            alt=""
                            onClose={()=>{this.setState({ ...this.state, showEvmModal: false, evmType:null , evmToShow:null})}}
                        />
                    )
                    
                }
                {
                     <Modal
                        isOpen={this.state.showEvmModal && this.isVideo(this.state.evmType)}
                      
                        onRequestClose={()=>{this.setState({ ...this.state, showEvmModal: false, evmType:null , evmToShow:null})}}
                       
                        contentLabel=""
                    >
                          <button className="btn btn-success" style = {{marginBottom:20}} onClick={()=>{this.setState({ ...this.state, showEvmModal: false, evmType:null , evmToShow:null})}}>Close</button>
                        <video style={{ height: '100%', width: '100%',borderRadius:5, border: '1px solid #dddddd' }} autoPlay className="w-100 rounded" loop muted>
                                                            <source src={NFT_BACKEND_API.concat(this.state.evmToShow ?? '')}/>
                                                        </video>
                      
                    </Modal>

                    
                }
            </main>
        );
    }
}
export default ExtrinsicDetails;

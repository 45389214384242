import invoke from '../utils/invoke';
import { SERVER_END_POINT } from '../config.json';

export const getExtrinsicByHash = (hash) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/extrinsic/${hash}`,
        method  :   'GET'
    })
}

export const listExtrinsics = (pageNumber = 1) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/extrinsics?page=${pageNumber}`,
        method  :   'GET'
    })
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
}));

export default ({ count, page = 1, onChangeListener = () => {} }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Pagination page={page} onChange={(e, value) => onChangeListener(value)} count={count} color="#302b3c" />
        </div>
    )
}
import React, { Component } from 'react';
import ListItem from './ListItem/ListItem';
import { getTransfer } from '../../../api/account';
import Pagination from '../../Layout/Pagination';
import Loader from '../../Layout/Loader';
import downloadAsJson from '../../../utils/downloadAsJson';

export default class Transfers extends Component {

    state = {
        data    :   [],
        meta    :   {
            currentPage :   0
        },
        isLoading   :   true,
        timeTypeToggle   : false,
    }

    componentDidMount() {
    
     
        if(this.props.address) this.invokeTransfersList();
    }
   
    componentDidUpdate(prevProps) {
 
        if (this.props.address !== prevProps.address) {
           
            this.setState({
                ...this.state,
                data    :   [],
                meta    :   {
                    currentPage: 0 
                }
            }, this.componentDidMount);
        }
        
        if (this.props.isRefresing !== prevProps.isRefresing) {
            if(this.props.isRefresing)
                this.invokeTransfersList();
        }
    }

    invokeTransfersList = async (page = 0) => {
        this.setState({ ...this.state, isLoading: true }, async () => {
            try {
                const { data } = await getTransfer(this.props.address, page);
              
              
                this.setState({ ...this.state, ...data, isLoading: false }, () => {
                    this.props.setPagination(
                        <Pagination page={page + 1} count={this.props.paginationsCount} onChangeListener={(page) => this.invokeTransfersList(page - 1)} />
                    )
                    this.props.setHistorian && this.props.setHistorian(data.data);
                });
            } catch(e) {}
        })
    }

    render() {
      
        const { data, isLoading, timeTypeToggle } = this.state;
     
        return (
            <React.Fragment>
                <thead>
                    <tr>
                        {/* <th width="10%">Rank</th> */}
                        <th width="15%">Tx HASH</th>
                        <th width="15%">Account</th>
                        <th width="11%">IN <i style={{ color: 'green', fontSize: '10px' }} className="fas fa-arrow-down"></i> / OUT <i style={{ color: 'red', fontSize: '10px' }} className="fas fa-arrow-up"></i></th>
                        <th width="15%">Method</th>
                        <th width="15%">Balance</th>
                        <th width="20%">Time <i onClick={() => this.setState({ ...this.state, timeTypeToggle: !timeTypeToggle }) } style={{ cursor: 'pointer', fontSize: '12px', color: '#58595a' }} className={`fa${ timeTypeToggle ? 's' : 'r' } fa-clock`}
                        ></i></th>
                        <th width="10%">Status</th>
                    </tr>
                </thead>
                <tbody ref="AccountPageTableBodyRef">
                    {
                        data.map((_data, index) => <ListItem key={index} {..._data} index={index + 1} timeTypeToggle={timeTypeToggle} address={this.props.address}  />)
                    }
                </tbody>
                { <div className="container-fluid"> { isLoading && <Loader /> }</div> }
                { data.length === 0 && !isLoading && <label style={{ marginTop: '20px' }} className="text-secondary text-center">No Record Found</label> }
            </React.Fragment>
        )
    }
}
export const setBlockList = blocks => dispatch => {
    dispatch({
        type:   'SET_BLOCK_LIST',
        payload:    blocks
    })
}

export const setSignedExtrinsicsCount = count => dispatch => {
    dispatch({
        type:   'SET_SIGNED_EXTRINSICS_COUNT',
        payload:    count
    })
}

export const setNucoinUSDPrice = count => dispatch => {
    dispatch({
        type:   'SET_NUCOIN_USD_PRICE',
        payload:    count
    })
}

export const setTransfersCount = count => dispatch => {
    dispatch({
        type:   'SET_TRANSFERS_COUNT',
        payload:    count
    })
}

export const setTotalIssuance = issuance => dispatch => {
    dispatch({
        type:   'SET_TOTAL_ISSUANCE',
        payload:    issuance
    })
}

export const setHoldersCount = holders => dispatch => {
    dispatch({
        type:   'SET_HOLDERS_COUNT',
        payload:    holders
    })
}
export const setCirculation= circulation => dispatch => {
    dispatch({
        type:   'total_circulation',
        payload:    circulation
    })
}
export const setLockedValue = lockedValue => dispatch => {
    dispatch({
        type:   'locked_value',
        payload:    lockedValue
    })
}
import invoke from '../utils/invoke';
import { SERVER_END_POINT } from '../config.json';

export const getAccount = (address) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/account/${address}`,
        method  :   'GET'
    })
}

export const getTransfer = (address, page = 0) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/account/transfers/${address}/?page=${page}`,
        method  :   'GET'
    })
}

export const getOtherExtrinsics = (address, page = 0) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/account/extrinsics/${address}/?page=${page}`,
        method  :   'GET'
    })
}

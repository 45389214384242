import React, { Component } from 'react';
import { listBlocks } from '../../api/block';
import Loader from '../Layout/Loader';
import SearchBar from '../Layout/SearchBar';
import BlockItem from './BlockItem/BlockItem';
import $ from 'jquery';
import 'datatables.net';
import Pagination from '../Layout/Pagination';
import downloadAsJson from '../../utils/downloadAsJson';
import Breadcrumb from '../Layout/Breadcrumb';

class Blocks extends Component {

    state = {
        blocks  :   [],
        meta    :   {
            pages   :   1,
            currentPage   :   1,
        },
        isLoading   :   true,
        timeTypeToggle  :   false
    }

    invokeListBlocks = async (pageNumber = 0) => {
        try {
            const { data: { data, meta } } = await listBlocks(pageNumber);
            
            this.setState({ ...this.state, blocks: data, meta }, () => this.setState({ ...this.state, isLoading: false }) );
        } catch(e){
        }
    }

    componentDidMount() {
       
        this.invokeListBlocks();
        this.jqueryDatatable();
    }

    jqueryDatatable = () => {
        $('#BlockPageTable').DataTable({
            "fnInfoCallback": function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
                return '';
            },
            lengthChange: false,
            pageLength: 10,
            info: true,
            ordering: false,
            
            autoWidth: false,
            language: {
                paginate: {
                    previous: '<i class="fas fa-arrow-left"></i>',
                    next: '<i class="fas fa-arrow-right"></i>',
                },
                sEmptyTable:
                    '<div>' +
                    '</div>',
            },
            initComplete: (settings, json) => {
                $('#BlockPageTable_info').appendTo('.AppendInfo');
                $('#BlockPageTable_paginate').appendTo('.AppendPagination');
            },
        });
    }

    render() {
        const { blocks, meta: { pages, currentPage }, isLoading, timeTypeToggle } = this.state;

        return (
            <main className="mb-5">
                <Breadcrumb />
                <section id="SearchBox">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="BlockPage">
                    <div className="container">
                        <div className="WhiteBox rounded p-4">
                            <table style={{ minHeight: '533px' }} className="Data_tables rounded mb-4" id="BlockPageTable">
                                <thead>
                                    <tr>
                                        <th width="10%">Block { isLoading && <Loader/> }</th>
                                        <th width="11%">Parent ID{ isLoading && <Loader/> }</th>
                                        <th width="15%">Parent Hash{ isLoading && <Loader/> }</th>
                                        <th width="15%">Hash{ isLoading && <Loader/> }</th>
                                        <th width="12%">Extrinsics{ isLoading && <Loader/> }</th>
                                        <th width="10%">Events{ isLoading && <Loader/> }</th>
                                        <th width="17%">Time <i onClick={() => this.setState({ ...this.state, timeTypeToggle: !timeTypeToggle }) } style={{ cursor: 'pointer', fontSize: '12px', color: '#58595a' }} className={`fa${ timeTypeToggle ? 's' : 'r' } fa-clock`}></i> { isLoading && <Loader/> }</th>
                                        <th width="15%">Status{ isLoading && <Loader/> }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        blocks.map(block => <BlockItem key={block.hash} {...block} timeTypeToggle={timeTypeToggle} />)
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center justify-content-between gx-5">
                                <Pagination
                                    count={pages}
                                    page={currentPage}
                                    onChangeListener={(target) => this.setState({ ...this.state, isLoading: true }, () => this.invokeListBlocks(target)) }
                                />
                                {
                                    blocks && blocks.length > 0 ? (
                                    <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                        <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                            if(blocks.length > 0) downloadAsJson(blocks, 'blocks');
                                        }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                                    </div>
                                    ) : null
                                }
                                
                                {/* <div className="col-md-auto AppendInfo text-center text-md-right mb-3 mb-md-0" />
                                <div className="col-md-auto AppendPagination text-center text-md-right" /> */}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default Blocks;

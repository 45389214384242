import React, { Component } from 'react';
import { listExtrinsics } from '../../api/extrinsic';
import Loader from '../Layout/Loader';
import SearchBar from '../Layout/SearchBar';
import ExtrinsicItem from './ExtrinsicItem/ExtrinsicItem';
import $ from 'jquery';
import 'datatables.net';
import Pagination from '../Layout/Pagination';
import downloadAsJson from '../../utils/downloadAsJson';
import Breadcrumb from '../Layout/Breadcrumb';

class Extrinsics extends Component {

    state = {
        extrinsics  :   [],
        meta    :   {
            pages   :   1,
            currentPage   :   1,
        },
        isLoading   :   true,
        timeTypeToggle  :   false
    }

    invokeListExtrinsics = async (pageNumber = 0) => {
        try {
            const { data: { data, meta } } = await listExtrinsics(pageNumber);
            this.setState({ ...this.state, extrinsics: data, meta }, () => this.setState({ ...this.state, isLoading: false }) );
        } catch(e){
        }
    }

    componentDidMount() {
        this.invokeListExtrinsics();
        this.jqueryDatatable();

    }

    jqueryDatatable = () => {
        function format ( d ) {
            // `d` is the original data object for the row
            return '<div class="WhiteBox lightGrey"><table cellpadding="5" cellspacing="0" border="0" class="w-100">'+
                        '<tr>'+
                            '<td>Dest</td>'+
                            '<td>ID</td>'+
                            '<td><a href="#" id="sample">2bsRXVKED2pzB1xHgLRJm7PMufsTBDU8tmTrgJ9a8teYybNp</a><button type="button" class="btn p-0 ml-2 bg-transparent shadow-none" href="#" onclick="CopyToClipboard(\'sample\');return false;"><i class="far fa-copy"></i></button></td>'+
                        '</tr>'+
                        '<tr>'+
                            '<td>Value</td>'+
                            '<td colspan="2">8,136 </td>'+
                        '</tr>'+
                    '</table></div>';
        }
        $(document).ready(function() {
            var table = $('#ExtrinsicsPageTable').DataTable( {
                "fnInfoCallback": function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
                    // return iStart +" - "+ iEnd +" of "+ iMax;
                },
                order: [1, 'asc'],
                lengthChange: false,
                pageLength: 10,
                info: true,
                paging: !true,
                ordering: false,
                autoWidth: false,
                language: {
                    paginate: {
                        previous: '<i class="fas fa-arrow-left"></i>',
                        next: '<i class="fas fa-arrow-right"></i>',
                    },
                    sEmptyTable:
                        '<div>' +
                        '</div>',
                },
                initComplete: (settings, json) => {
                    $('#ExtrinsicsPageTable_info').appendTo('.AppendInfo');
                    $('#ExtrinsicsPageTable_paginate').appendTo('.AppendPagination');
                },
            } );
    
            // Add event listener for opening and closing details
            // $('#ExtrinsicsPageTable tbody').on('click', 'td.details-control', function () {
            //     var tr = $(this).closest('tr');
            //     var row = table.row( tr );
            //     if ( row.child.isShown() ) {
            //         // This row is already open - close it
            //         row.child.hide();
            //         tr.removeClass('shown');
            //     }
            //     else {
            //         // Open this row
            //         row.child( format(row.data()) ).show();
            //         tr.addClass('shown');
            //     }
            // });
        });
    }

    render() {
        const { extrinsics, meta: { pages, currentPage }, isLoading, timeTypeToggle } = this.state;

        return (
            <main className="mb-5">
                <Breadcrumb />
                <section id="SearchBox">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <SearchBar/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section style={{ marginTop: '30px' }} id="ExtrinicsHistory">
                    <div className="container">
                        <h5 className="mb-3"><i className="fas fa-chart-bar" aria-hidden="true" /> Extrinsic History</h5>
                        <div className="WhiteBox rounded py-2 px-3 mb-4">
                            <figure className="m-0">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination mb-0">
                                        <li className="page-item mx-1"><a className="page-link shadow-none" href="javascript:void(0);">1H</a></li>
                                        <li className="page-item mx-1"><a className="page-link shadow-none" href="javascript:void(0);">6H</a></li>
                                        <li className="page-item mx-1"><a className="page-link shadow-none LabelActive" href="javascript:void(0);">1D</a></li>
                                    </ul>
                                </nav>
                                <div id="ExtrinsicsHistoryGraph" />
                            </figure>
                        </div>
                    </div>
                </section> */}
                <section style={{ marginTop: '30px' }} id="ExtrinsicsPage">
                    <div className="container">
                        {/* <div className="row align-items-center mb-3">
                            <div className="col-lg-auto col-6 mr-sm-auto order-lg-1 order-1">
                                <h5 className="mb-0"><i className="fas fa-chart-bar" aria-hidden="true" /> For All (49168)</h5>
                            </div>
                            <div className="col-lg-auto col-sm-4 d-sm-flex align-items-center order-lg-2 order-3 mt-lg-0 mt-3">
                                <label className="mr-2 mb-2 mb-sm-0 fw-500">Module</label>
                                <select className="form-control border CustomDropDown mr-2">
                                    <option>Option</option>
                                    <option>Option2</option>
                                    <option>Option</option>
                                </select>
                            </div>
                            <div className="col-lg-auto col-sm-4 d-sm-flex align-items-center order-lg-3 order-4 mt-lg-0 mt-3">
                                <label className="mr-2 mb-2 mb-sm-0 fw-500">Call</label>
                                <select className="form-control border CustomDropDown mr-2">
                                    <option>Option</option>
                                    <option>Option</option>
                                    <option>Option</option>
                                </select>
                            </div>
                            <div className="col-lg-auto col-sm-4 d-sm-flex align-items-center order-lg-4 order-5 mt-lg-0 mt-3">
                                <label className="mr-2 mb-2 mb-sm-0 fw-500">Sign</label>
                                <select className="form-control border CustomDropDown mr-2">
                                    <option>Option</option>
                                    <option>Option</option>
                                    <option>Option</option>
                                </select>
                            </div>
                            <div className="col-lg-auto col-6 order-lg-5 order-2 text-right">
                                <a href="#" className="btn BtnDark">More</a>
                            </div>
                        </div> */}
                        <div className="WhiteBox rounded p-4">
                            <table  style={{ minHeight: '533px' }}  className="Data_tables rounded mb-4" id="ExtrinsicsPageTable">
                                <thead>
                                    <tr>
                                        <th width="15%">Extrinsic ID { isLoading && <Loader /> }</th>
                                        <th width="10%">Block { isLoading && <Loader /> }</th>
                                        <th width="20%">Extrinsic Hash { isLoading && <Loader /> }</th>
                                        <th width="10%">Section { isLoading && <Loader /> }</th>
                                        <th width="15%">Method { isLoading && <Loader /> }</th>
                                        <th width="10%">Signed { isLoading && <Loader /> }</th>
                                        <th width="20%">Time  <i onClick={() => this.setState({ ...this.state, timeTypeToggle: !timeTypeToggle }) } style={{ cursor: 'pointer', fontSize: '12px', color: '#58595a' }} className={`fa${ timeTypeToggle ? 's' : 'r' } fa-clock`}></i> { isLoading && <Loader /> }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        extrinsics.map(extrinsic => <ExtrinsicItem key={extrinsic.hash} {...extrinsic} timeTypeToggle={timeTypeToggle} />)
                                    }
                                    {/* <tr>
                                        <td><a href="#">451312-2</a></td>
                                        <td>451312</td>
                                        <td><a className="WalletDots" href="#">0x19febd91794aab5af5a54631af97aa9e7ee162e60243b8892d4db2acf2bb794b</a></td>
                                        <td>1 hr 22 mins ago</td>
                                        <td><i className="far fa-check-circle text-success Fsize_20" /></td>
                                        <td><a href="#">balances(transfer)</a></td>
                                        <td className="details-control"><button type="button" className="btn BtnDark shadow-none py-0 px-2"><i className="fas fa-chevron-right" /></button></td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div className="row align-items-center justify-content-between gx-5">
                            <Pagination
                                    // current={currentPage}
                                    count={pages}
                                    page={ currentPage + 1 }
                                    onChangeListener={(target) => this.setState({ ...this.state, isLoading: true }, () => this.invokeListExtrinsics(target - 1)) }
                                />
                                {
                                    extrinsics && extrinsics.length > 0 ? (
                                    <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                        <a className="btn BtnDark"  style={{ color: 'white' }} onClick={() => {
                                            if(extrinsics.length > 0) downloadAsJson(extrinsics, 'extrinsics');
                                        }} ><i className="fas fa-download mr-2" /> Download Page Data</a>
                                    </div>
                                    ) : null
                                }
                                 
                                {/* <div className="col-md-auto text-center text-md-left mb-3 mb-md-0">
                                    <a className="btn BtnDark" href="#"><i className="fas fa-download mr-2" /> Download Page Data</a>
                                </div>
                                <div className="col-md-auto AppendInfo text-center text-md-right mb-3 mb-md-0" />
                                <div className="col-md-auto AppendPagination text-center text-md-right" /> */}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default Extrinsics;

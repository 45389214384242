import { io } from "socket.io-client";
import { SOCKET } from '../config.json';

const INITIAL_STATE = {
    connection  :  io(SOCKET),
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'SET_SOCKET_CONNECTION':
        return { ...state, connection: action.payload };
      default:
        return state;
    }
  }
import  React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import timeAgo from 'time-ago';
import moment from 'moment';

export default ({ hash, block_id, method, section, index, data, is_signed, signer, nonce, timestamp, isExpaned = false,  timeTypeToggle = false }) => {

    const [ isShown, setShown ] = useState(false);

    useEffect(() => {
        setShown(isExpaned);
    }, [ isExpaned ]);

    const [ timeToggle, setTimeToggle ] = useState(false);

    useEffect(() => {
        setTimeToggle(timeTypeToggle)
    }, [timeTypeToggle]);
    
    return (
        <React.Fragment>
        <tr className={ isShown ? 'shown' : '' }>
            <td><Link to={`/extrinsic/${hash}`}>{ block_id }-{ index }</Link></td>
            <td><Link data-toggle="tooltip" title={hash} className="WalletDots" to={`/extrinsic/${hash}`}>{ hash }</Link></td>
            <td>{ timestamp ? timeToggle ? moment(timestamp).format('lll') : timeAgo.ago(timestamp) : 'N/A' }</td>
            <td>{ section }</td>  
            <td>{ method }</td>
            <td className="details-control">
                <button onClick={e => setShown(!isShown)} type="button" className="btn BtnDark shadow-none py-0 px-2"><i
                        className="fas fa-chevron-right"></i></button>
            </td>
            {/* <td>{ data }</td> */}
        </tr>
            <tr style={{ display: isShown ? '' : 'none' }} >
            <td colspan="6">
                <div className="WhiteBox lightGrey">
                    <table className="w-100" cellspacing="0" cellpadding="5" border="0">
                        <tbody>
                            {
                                JSON.parse(data || [] ).map((value, index) => (
                                    <tr>
                                        <td>{ index }</td>
                                        <td>{ JSON.stringify(value || {}) }</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
        </React.Fragment>
    )
}
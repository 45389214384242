import React from 'react';
import { Link } from 'react-router-dom';
import commaNumber from 'comma-number';
import timeAgo from 'time-ago';
import { timeAgoFormator } from '../../../utils/timeFormater'

export default ({ block_id, events_count, extrinsics_count, timestamp }) => (
    <div className="border-bottom d-flex justify-content-between mb-3">
        <div className="Text-Box">
            <div className="d-flex font-weight-bold Fsize_16">
                <label className="mb-0 mr-3">Block#</label>
                <Link to={`/block/${block_id}`}><a>{ commaNumber(block_id) }</a></Link>
            </div>
            <div className="d-flex">
                <label className="mr-3">Includes</label>
                <Link to={`/block/${block_id}`}><a className="mr-3" href="#">{ extrinsics_count } Extrinsic</a></Link>
                <Link to={`/block/${block_id}`}><a className="mr-3" href="#">{events_count} Event</a></Link>
            </div>
        </div>
        <div className="d-flex align-items-center">
            <span>{ timeAgoFormator(timestamp) }</span>
            <i className="far fa-check-circle text-success ml-2 Fsize_20" />
        </div>
    </div>
)
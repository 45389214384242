import React, { Component } from 'react';
import {coinMint, coinMintPossible, getUserBalance} from '../../api/mintTranferBalance'
import $ from 'jquery';
import 'datatables.net';
import { mnemonicValidate, validateAddress } from '@polkadot/util-crypto';
import { Keyring } from '@polkadot/keyring';
import SearchBar from '../Layout/SearchBar';
import Breadcrumb from '../Layout/Breadcrumb';
import bigDecimal from 'js-big-decimal';
import { connect } from 'react-redux';
import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';
import {USICOUNT} from '../../config.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import generateSignature from './GenerateSignature';

import './MintStyles.css'

class MintCoin extends Component {

    keyring = new Keyring({ type: 'sr25519' });
    pair    = null;

    state = {
        mnemonics: null,
        address: null,
        value: null,
        balance: null,
        message: {
            value: null,
            isErrored: false
        },
        isLoading: false,
        validMiner:false,
        canMint:false
    }

    on = e => {
        e.preventDefault();

        if(e.target.name === 'mnemonics' && mnemonicValidate(e.target.value)) {
            this.pair = this.keyring.addFromUri(e.target.value);
            this.setState({ ...this.state, [e.target.name]: e.target.value, address: this.pair.address });

                getUserBalance(this.pair.address).then((balanceData)=>{
                    this.setState({ ...this.state, balance: `${balanceData?.data?.balance}` });
               }).catch(function (error) {
                   this.setState({ ...this.state, balance: 0 ,});
               });
        } else this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    processValidity = async (address) =>{
        if (this.state.canMint===false && this.state.validMiner===false ){
            try {
                const response = await coinMintPossible(address);
                const r_validMiner = response.data['validMiner']
                const r_canMint = response.data['canMint']
                this.setState({ ...this.state, validMiner: r_validMiner,canMint:r_canMint })
            } catch(e) {
                toast.error(e.response.data["message"], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    console.log("error 1:",e)
                // try{
                //     alert(e.response.data["message"])
                // } catch(e) {
                //     console.log(e)
                // }
            }
        }
    } 

    mintingCoin = async() => {
        this.setState({isLoading:true})
        const address = this.state.address
        const mnemonics = this.state.mnemonics
        var signatureData = await generateSignature(mnemonics, address, USICOUNT);

        if (address != null && mnemonics!=null){
            try{
                const response = await coinMint({ accountId: address,signature: signatureData ,usiCount: USICOUNT})
                if (response.data["hash"]){
                    this.setState({isLoading:false})
                    toast.success(`You just minted. Your minted hash :${response.data["hash"]}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }     
            } catch(e) {
                    var message = `${e.response.data["message"]} you still have ${e.response.data["hoursLeft"]} hours to mint again`
                    this.setState({isLoading:false})

                    toast.error(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    console.log("error :",e)
            }
        }
    }

    componentDidUpdate(){
        const address = this.state.address 
        if (address !==null && this.state.balance !==null){
            this.processValidity(address)
        }
    }

    validateSR588Address(address) {
        
        try {
            encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));
            return true;
          } catch (error) {
            return false;
          }
    }

    validateNumber(value) {
        try {
            const number = parseInt(value);
            return !Number.isNaN(number);

        } catch(e) {
            return false;
        }
    }

    render() {

        return (
	        <main class="mb-5">
                <Breadcrumb />
            <section id="SearchBox">
                <div className="row justify-content-between align-items-center mb-4">
                    <div className="container">
                        <SearchBar />
                    </div>
                </div>
            </section>
            <section id="TransferForm">
                <div className="container">
                    <h5 className="mb-3"><i className="fas fa-chart-bar" aria-hidden="true"></i> Mint </h5>
                    <div className="WhiteBox rounded py-2 px-3 mb-4">
                    <div className="mint-body">
                        <div className="row align-items-end">
                        <div className="col-sm-6 col-lg-10 form-group">
                            <label for="Receiver">Mnemonics</label>
                            <input disabled={this.state.isLocked|| this.state.mnemonics!==null} onChange={this.on} value={this.state.mnemonics || ''} placeholder="Paste account mnemonics here" type="text" style={{ borderColor: this.state.mnemonics && mnemonicValidate(this.state.mnemonics) ? '' : this.state.mnemonics !== null ? 'red' : '' }} className="form-control shadow-none" name="mnemonics" autoComplete="off"/>
                        </div>
                        <div className="col-sm-6 form-group">

                            {
                                this.state.isLoading && this.state.message.value && <div style={{ marginBottom: '-7px' }} className="alert alert-info" role="alert">
                                    { this.state.message.value }
                                </div>
                            }
                            
                            {
                                this.state.message.isErrored && <div style={{ marginBottom: '-7px' }} className="alert alert-danger" role="alert">
                                    { this.state.message.value }
                                </div>
                            }
                            
                            {
                                this.state.message.value && !this.state.isLoading && !this.state.message.isErrored && <div style={{ marginBottom: '-7px' }} className="alert alert-success" role="alert">
                                    { this.state.message.value }
                                </div>
                            }                
                        </div>
                        </div>
                        <div className="row align-items-end">
                            <div className="col-sm form-group">
                                <label for="mnemonics">Address { this.state.address && <b style={{ color: !this.state.isLoading && this.state.message.value && !this.state.message.isErrored ? 'green' : 'black' }}>( {this.state.balance} NUC )</b>  }</label>
                                <input disabled value={this.state.address || ''} placeholder="----" type="text" className="col-sm-6 col-lg-10 form-group form-control shadow-none" />
                            </div>
                            <div className="w-100"></div>
                            <div className="buttons col-sm-6 col-lg-9 form-group">
                                <div className="row" style={{ paddingLeft: '26px' }}>
                                    <div className="col-6">
                                        <button onClick={this.mintingCoin} disabled={this.state.canMint===false && this.state.validMiner===false || this.state.isLoading===true} style={{ width: '100%' }} className="btn BtnDark px-5 font-weight-bold shadow-none">Mint{ this.state.isLoading && <span className="fa fa-spinner fa-spin"/>}</button>
                                    </div>
                                    <div className="col-6">
                                        <button disabled={this.state.isLoading} onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                mnemonics: null,
                                                address: null,
                                                balance: null,
                                                value: null,
                                                message: {
                                                    value: null,
                                                    isErrored: false
                                                },
                                                isLoading: false,
                                                validMiner:false,
                                                canMint:false
                                            })
                                        }} style={{ width: '100%' }} className="btn BtnDark px-5 font-weight-bold shadow-none">Refresh</button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </div>
                    <div className="container">
                        <div style={{ minHeight: '10px' }}>
                        </div>
                    </div>
                </div>
		    </section>
           <ToastContainer />
		</main>
        )
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps)(MintCoin);
import React, { Component } from 'react';
// import Breadcrumb from '../Layout/Breadcrumb';

class TransactionDetails extends Component {

    render() {

        return (
            <main className="mb-5">
                {/* <Breadcrumb /> */}
                <section id="SearchBox">
                    <div className="container">
                        <div className="row justify-content-between align-items-center mb-4">
                            <div className="col-lg-3 d-flex align-items-center">
                                <h4 className="mb-0">Transaction Detail</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="BlockDetailPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="table-responsive">
                                            <table className="table mb-0">

                                                <thead style={{backgroundColor: "#f8f9fa"}}>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Text Here</th>
                                                        <th>Text Here</th>
                                                        <th>Text Here</th>
                                                        <th>Text Here</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">3</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default TransactionDetails;
import invoke from '../utils/invoke';
import { SERVER_END_POINT } from '../config.json';

export const getBlockDetailByID = (blockId) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/block/${blockId}`,
        method  :   'GET'
    })
}

export const listBlocks = (pageNumber = 0) => {
    return invoke({
        url :   `${SERVER_END_POINT}/v1/api/blocks?page=${pageNumber}`,
        method  :   'GET'
    })
}
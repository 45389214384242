import React from 'react';
import { Link } from 'react-router-dom';

const path = {
    'extrinsic': [
        { name: 'Home', path: '/' },
        { name: 'Extrinsic', path: '/extrinsic', isActive: true }
    ],
    'extrinsics': [
        { name: 'Home', path: '/' },
        { name: 'Extrinsics', path: '/extrinsics', isActive: true }
    ],
    'block': [
        { name: 'Home', path: '/' },
        { name: 'Block', path: '/block', isActive: true }
    ],
    'blocks': [
        { name: 'Home', path: '/' },
        { name: 'Blocks', path: '/blocks', isActive: true }
    ],
    'account': [
        { name: 'Home', path: '/' },
        { name: 'Account', path: '/account', isActive: true }
    ],
    'transfer': [
        { name: 'Home', path: '/' },
        { name: 'Transfer Fund', path: '/transfer/fund', isActive: true }
    ],
    'coin': [
        { name: 'Home', path: '/' },
        { name: 'Mint Coin', path: '/coin/mint', isActive: true }
    ]
}

export default () => {
    const location = window.location.pathname.split('/')[1];

    return (
        <nav className="container" aria-label="breadcrumb">
            <ol className="breadcrumb">
                {
                    path[location].map((_path, index) => <li key={index} className={`breadcrumb-item ${_path.isActive && 'active'}`}>{ _path.isActive ? `${_path.name}` : <Link to={_path.path}>{ _path.name }</Link>}</li> )
                }
            </ol>
        </nav>
    )
}